import React from 'react'
import { Root, RootBody, RootButton, RootCard, RootImage } from './Item.style'
import { Button } from '@/components/Buttons/Button'
import { Typography } from '@mui/material'
import HeartOutline from '@/components/Icons/HeartOutline'
import { useNavigate } from 'react-router-dom'
import { separadorMiles } from '@/utils/separadorMiles'
import config from '@/config';

const Item = ({
  item
}) => {
  const navigate = useNavigate()
  return (
    <Root onClick={() => navigate('/property?id=' + item.id)}>
      {item.images && item.images.length > 0 ? (
        <RootImage style={{ backgroundImage: `url(${config.API_URL}/${item.images[0].url.replace(/\\/g, '/')})` }}>
        </RootImage>
      ) : (
        <RootImage>
          No Image Available
        </RootImage>
      )}
      <RootCard>
        <RootBody>
          <div>
            {/* <Typography variant='subtitle1'><b>{item.city}</b></Typography> */}
            <div style={{ marginTop: 5 }}>
              <Typography variant='subtitle1'>{item.address}</Typography>
            </div>
            <div style={{ marginTop: 5 }}>
              <Typography variant='subtitle1'>{item.size}m2 / Garantia ${separadorMiles(item.priceGuarantee)}</Typography>
            </div>
          </div>
          <div style={{ position: 'relative', right: -14, top: -6 }}>
            <HeartOutline />
          </div>
        </RootBody>
        <RootButton>
          <div>
            <Button type='outlined'><b>{separadorMiles(item.price)} UF</b></Button>
          </div>
        </RootButton>
      </RootCard>
    </Root>
  )
}

export default Item