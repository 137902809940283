import React from 'react'
import { Root, RootBody, RootButton, RootCard, RootImage } from './Item.style'
import { Button } from '@/components/Buttons/Button'
import { Typography } from '@mui/material'
import HeartOutline from '@/components/Icons/HeartOutline'
import { useNavigate } from 'react-router-dom'

const Item = ({
  index
}) => {
  const navigate = useNavigate()
  return (
    <Root onClick={() => navigate('/property')}>
      <RootImage style={{ backgroundImage: `url(${require(`@/assets/images/build/Element-${index}.png`)})` }}>
      </RootImage>
      <RootCard>
        <RootBody>
          <div>
            <Typography variant='body2'><b>Santiago</b></Typography>
            <div style={{ marginTop: 5 }}>
              <Typography variant='caption'>Mapocho 1512 Depto 633</Typography>
            </div>
            <div style={{ marginTop: 5 }}>
              <Typography variant='caption'>28m2 / Garantia $3.000.000</Typography>
            </div>
          </div>
          <div style={{ position: 'relative', right: -14, top: -6 }}>
            <HeartOutline />
          </div>
        </RootBody>
        <RootButton>
          <div>
            <Button type='outlined'><b>Min UF 390</b></Button>
          </div>
        </RootButton>
      </RootCard>
    </Root>
  )
}

export default Item