
import { maxSizeMobile, maxSizeTablet } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
  margin-left: 38px; 
  margin-top: 38px;
  margin-right: 38px;
  @media (max-width: ${maxSizeMobile}) {
    margin-left: 0px; 
    margin-top: 0px;
    margin-right: 0px;
  }
`
export const RootCarrousel = styled.div`
  @media (min-width: ${maxSizeMobile}) {
    margin-right: 40px;
  }
`

export const RootElements = styled.div`
  display: flex;
  @media (max-width: ${maxSizeMobile}) {
    margin-top: 30px;
  }
  > div:first-child {
    border-right: 1px solid black;
  }
  > div:first-child > div:first-child {
    border-bottom: 1px solid black;
  }
  > div:last-child > div:first-child {
    border-bottom: 1px solid black;
  }
  > div:first-child > div:nth-child(2) {
    border-bottom: 1px solid black;
  }
  > div:last-child > div:nth-child(2)  {
    border-bottom: 1px solid black;
  }
  > div > div {
    width: 125px;
    height: 125px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column
  }
  > div > div > img {
        width: 50px;
        height: 50px;
      }
  p {
    padding: 0px 20px;
    text-align: center;
  }
  @media (max-width: ${maxSizeMobile}) {
      > div > div {
        width: 150px;
        height: 150px;
      }
      > div > div > img {
        width: 60px;
        height: 60px;
      }
  }
`

export const RootImage = styled.img`
  
`

export const MapBackground = styled.div`
  height:0px;
  margin-top: 20px;
  background-size: cover;
  padding-bottom: 30%;
  width: 100%;
  @media (max-width: ${maxSizeMobile}) {
       padding-bottom: 60%;
  }
`

export const IconsImages = styled.img`
  width: 80px;
  margin-bottom: 15px;
`

export const rootGrid = css`
 @media (max-width: ${maxSizeMobile}) {
   margin-left:26px !important;
   margin-right:26px !important;
   :nth-child(3) {
      margin-top: 40px !important;
    }
   :nth-child(4) {
      margin-top: 10px !important;
    }
   :nth-child(5) {
      margin-top: 30px !important;
    }
  }
`
export const mapButton = css`
  max-width: 230px;
  margin-bottom: 30px;
  @media (max-width: ${maxSizeMobile}) {
    margin-bottom: 25px;
    > div {
      height: 40px;
      padding-left: 40px;
      padding-right: 40px;
    }
   
  }
`