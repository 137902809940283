import React from 'react'
import AccordionMUI from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ChevronRight from '@/components/Icons/ChevronRight';
import { ButtonStyle, DogImage, Root, RootButtonDog } from './index.style';
import { Button } from '@/components/Buttons/Button';
import DownloadIcon from '@/components/Icons/DownloadIcon';

const AccordionGarantias = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const dogSrc = require('@/assets/images/dog.webp')

    const getBody = (i) => {
        switch (i) {
            case 1:
                return (
                    <ul>
                        <li>Garantía mínima.</li>
                        <li>Diferencia de la subasta o remate adjudicado: (Adjudicación - Garantía mínima).</li>
                        <li>Gestión: Comisión del portal y/o martillero que asciende a un 2%+IVA o 3%+IVA sobre el valor total de adjudicación, según el tipo de operación. Este porcentaje se encuentra explícito junto a cada propiedad en venta.</li>
                        <li>Escrituración de la propiedad adquirida Costo base de UF 17 con tope de UF 50 en función del precio de compra + IVA).</li>
                        <li>Gastos notariales: Costo base de $50.000 + 0,16% sobre el precio de compra.</li>
                        <li>Conservador de Bienes Raíces (CBR): Costo base de $250.000 o el 0,45 % sobre el precio de compra.</li>
                        <li>Los costos generales se encuentran alineados al valor de adjudicación.</li>

                    </ul>
                )
            case 2:
                return (
                    <ul>
                        <li><u>Sí</u>. <u>Esta opción solo está disponible en “compra directa”,</u> la cual permite que un ejecutivo pueda abordar la
                            oferta que tu propones por el inmueble, para luego enviarla a evaluación con el mandante o dueño. Si el
                            mandante o dueño aceptan tu oferta, se puede avanzar como una compra directa entre privados, por lo
                            que se emite una escritura de compraventa directa.
                        </li>

                    </ul>
                )
            case 3:
                return (
                    <ul>
                        <li>
                            No. Por el momento, solo está disponible la opción de pagar el diferencial de adjudicación completo con
                            transferencia bancaria, depósito bancario o pago desde tu wallet. Debes considerar que existe un plazo
                            para enterar este monto, por lo que recomendamos revisar las bases y condiciones de cada inmueble
                            publicado.
                        </li>

                    </ul>
                )
            default:
                break;
        }
    }

    return (
        <>
            {
                [
                    '1) ¿Cuáles son los costos generales en caso de que me adjudique un inmueble?',
                    '2) ¿Puedo comprar con hipotecario una propiedad?',
                    '3) ¿Puedo pagar en cuotas el diferencial de adjudicación?',
                ].map((i, key) => (
                    <Root>
                        <AccordionMUI expanded={expanded === 'panel' + key} onChange={handleChange('panel' + key)}>
                            <AccordionSummary
                                expandIcon={<ChevronRight />}
                                aria-controls="panel1-content"
                                id="panel1-header">
                                <Typography variant='body1'>
                                    <b>{i}</b>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    getBody(key + 1)
                                }
                            </AccordionDetails>
                        </AccordionMUI>
                    </Root>
                ))
            }
        </>
    )
}

export default AccordionGarantias