import React from 'react'
import Item from './Item/Item'
import { Grid } from '@mui/material'

const List = () => {
    return (
        <div>
            <Grid container spacing={2}>
                {
                     [...Array(4)].map((i,index) => (
                        <Grid item xs={12} md={6} lg={3}>
                            <Item index={index} />
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    )
}

export default List