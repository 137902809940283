import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import List from './List/List'
import TopFilters from './List/TopFilters/TopFilters'
import Filters from './Filters/Filters'
import axios from 'axios'
import config from '@/config';

const BodyList = () => {
  const [filterBuild, setFilterBuild] = useState('CASA')
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  console.log("properties", properties)

  const fetchProperties = async () => {
    try {
      const response = await axios.get(config.API_URL + '/properties');
      console.log("response", response)
      setProperties(response.data);
    } catch (error) {
      console.error('Error fetching properties', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProperties();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <Filters />
      </Grid>
      <Grid item xs={12} md={9}>
        <TopFilters
          filterBuild={filterBuild}
          setFilterBuild={setFilterBuild}
        />
        <List properties={properties} />
      </Grid>
    </Grid>
  )
}

export default BodyList