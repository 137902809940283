import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { RootDogHouse, RootItem } from './Carrousel.style'
import config from '@/config';

const Carrousel = ({ images }) => {
    return (
        <Carousel
            swipe={false}
            animation='slide'
            navButtonsAlwaysVisible
        >
            {
                images.map((image, i) => <Item key={i} image={image} />)
            }
        </Carousel>
    )
}

function Item(props) {
    return (
        <RootItem>
            <RootDogHouse style={{ backgroundImage: `url(${props.image.file ? props.image.id : config.API_URL +`/${props.image.url.replace(/\\/g, '/')}`})` }}>
            </RootDogHouse>
        </RootItem>
    )
}

export default Carrousel
