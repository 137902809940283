import login from '@/api/users/login'
import { Button } from '@/components/Buttons/Button'
import { useAuth } from '@/provider/AuthProvider'
import { Fn } from '@/utils/validations'
import { FormControlLabel, Grid, Input, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Form = ({ setIsAuth }) => {
  const [rut, setRut] = useState(null)
  const [password, setPassword] = useState(null)
  const [error, setError] = useState('')
  const [load, setLoad] = useState(false)
  const [message, setMessage] = useState(null)
  const [openModalForgot, setOpenModalForgot] = useState(false)

  const auth = useAuth()

  const navigate = useNavigate()

  const onSubmit = async () => {
    setError('')

    if (!rut) {
      return setError('Te faltó ingresar tu rut')
    }
    if (!Fn.validaRut(rut)) {
      return setError('Debes ingresar un rut válido')
    }
    if (!password) {
      return setError('Te faltó ingresar una contraseña')
    }
    if (password.length < 6) {
      return setError('La contraseña debe ser mínimo 6 letras')
    }

    let rutSend = rut.replace('-', '')
    setLoad(true)
    const res = await login({ rut: rutSend, password })
    console.log("res login =>", res)
    if (!res) {
      setLoad(false)
      return setError('Error en el sistema')
    }
    if (!res.data) {
      if (res.error) {
        setLoad(false)
        if (res.error.errorMessage) {
          return setError(res.error.errorMessage)
        } else {
          setLoad(false)
          return setError('Error')
        }
      } else {
        setLoad(false)
        return setError('Error')
      }
    }

    localStorage.setItem('token', res.token)
    setIsAuth(true)
    setMessage('¡Datos correctos! Ingresando...')
    setTimeout(() => {
      setLoad(false)
      auth.signin(res.data, () => {
        navigate('/')
      })
    }, 2000)
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='body1'>Selecciona una opción</Typography>
        </Grid>
        <Grid item container xs={12}>
          <RadioGroup defaultValue="client">
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <FormControlLabel control={<Radio defaultChecked />} label="Persona" value={'client'} />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel control={<Radio />} label="Empresa" value={'company'} />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder='Ingresa tu RUT'
            fullWidth
            value={rut}
            onChange={(e) => setRut(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder='Contraseña'
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            type="password"
          />
        </Grid>
        <Grid item xs={12} container justifyContent={'flex-end'}>
          <Typography variant='body1'>Olvidé mi contraseña</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={'center'}>
          <Button onClick={onSubmit} type='secondary' style={{ minWidth: 200 }}><b>Entrar</b></Button>
        </Grid>
        {
          error && <Typography variant='body1' color="red" mt={3} style={{ width: '100%', textAlign: 'center' }}>
            {error}</Typography>
        }
        <Grid item xs={12} container justifyContent={'center'}>
          <Link to={'/register'}>
            <Typography variant='body1'>No tengo cuenta</Typography>
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

export default Form