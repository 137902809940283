import React from 'react'
import { Table as TableMUI, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { DogSideImage, Root, tableCell, tableCellHead, tableHead, tableRoot, tableRow } from './index.style'

const Table = () => {
    const dogSideSrc = require('@/assets/images/dog-side.webp')

    return (
        <Root>
            <TableMUI className={tableRoot}>
                <TableHead className={tableHead}>
                    <TableRow className={tableRow}>
                        <TableCell className={tableCellHead} align='center'>
                            <Typography variant='body2'><b>SERVICIO</b></Typography>
                        </TableCell>
                        <TableCell className={tableCellHead} align='center'>
                            <Typography variant='body2'><b>PRECIO UF</b></Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        [
                            {
                                "name": "Contrato Arriendo",
                                "amount": "1,30"
                            },
                            {
                                "name": "Consulta Tributaria",
                                "amount": "1,30"
                            },
                            {
                                "name": "Imágenes Aéreas",
                                "amount": "1,79"
                            },
                            {
                                "name": "Promesa Compraventa",
                                "amount": "2,30"
                            },
                            {
                                "name": "Tasación Virtual",
                                "amount": "2,98"
                            },
                            {
                                "name": "Tasación Presencial",
                                "amount": "3,57"
                            },
                            {
                                "name": "Mandato General",
                                "amount": "3,00"
                            },
                            {
                                "name": "Contrato Compraventa",
                                "amount": "6,00"
                            },
                            {
                                "name": "Estudio de Títulos",
                                "amount": "7,00"
                            },
                            {
                                "name": "Posesión Efectiva",
                                "amount": "7,00"
                            },
                            {
                                "name": "Testamentos",
                                "amount": "8,00"
                            },
                            {
                                "name": "Adjudicación Judicial",
                                "amount": "40,00"
                            },
                            {
                                "name": "Curso Corretaje Propiedades",
                                "amount": "3,57"
                            },
                            {
                                "name": "Curso Remates Judiciales",
                                "amount": "8,93"
                            }
                        ]
                            .map(item => (
                                <TableRow className={tableRow}>
                                    <TableCell width={230} className={tableCell}>
                                        <Typography variant='body2'>
                                            {
                                                item.name
                                            }
                                        </Typography>
                                    </TableCell>
                                    <TableCell width={150} align='center' className={tableCell}>
                                        <Typography variant='body2'>
                                            {
                                                item.amount
                                            }
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))
                    }
                </TableBody>
            </TableMUI>
            <DogSideImage src={dogSideSrc} />
        </Root>
    )
}

export default Table