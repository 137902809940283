import { Button } from '@/components/Buttons/Button'
import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { hideOnMobile } from './index.style'
import { useAuth } from '@/provider/AuthProvider'
import { Fn } from '@/utils/validations'
import create, { Request } from '@/api/users/create'

const Form = ({
  setIsAuth
}) => {
  const [rut, setRut] = useState(null)
  const [userType, setUserType] = useState('persona')
  const [name, setName] = useState(null)
  const [last_name, setLast_name] = useState(null)
  const [email, setEmail] = useState(null)
  const [reEmail, setReEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [rePassword, setRePassword] = useState(null)
  const [error, setError] = useState('')
  const [load, setLoad] = useState(false)
  const [message, setMessage] = useState(null)

  const auth = useAuth()

  const navigate = useNavigate()

  const onSubmit = () => {
    setError('')
    if (!rut) {
      return setError('Te faltó ingresar tu rut')
    }
    if (!Fn.validaRut(rut)) {
      return setError('Debes ingresar un rut válido')
    }
    if (!name) {
      return setError('Te faltó ingresar un nombre')
    }
    if (isPersona && !last_name) {
      return setError('Te faltó ingresar un apellido')
    }
    if (!email) {
      return setError('Debes ingresar un correo')
    }
    if (!Fn.validateEmail(email)) {
      return setError('Debes ingresar un correo válido')
    }
    if (email !== reEmail) {
      return setError('Los correos ingresadas no coinciden')
    }
    if (!password) {
      return setError('Debes ingresar una contraseña')
    }
    if (password.length < 6) {
      return setError('La contraseña debe ser mínimo de 6 letras')
    }
    if (password !== rePassword) {
      return setError('Las contraseñas ingresadas no coinciden')
    }
    createClient()
  }

  const createClient = async () => {
    setError('')
    let rutSend = rut.replace('-', '')
    let req: Request = {
      rut: rutSend,
      password,
      userType,
      name,
      last_name,
      email,
    }
    console.log('req', req)
    setError('')
    setLoad(true)
    const res = await create(req)
    console.log("res register =>", res)
    if (!res) {
      setLoad(false)
      return setError('En estos momentos tu cuenta no pudo ser creada, por favor intente más tarde')
    }
    if (!res.data) {
      if (res.error) {
        setLoad(false)
        if (res.error.errorMessage) {
          return setError(res.error.errorMessage)
        } else {
          setLoad(false)
          return setError('En estos momentos tu cuenta no pudo ser creada, por favor intente más tarde 02')
        }
      } else {
        setLoad(false)
        return setError('En estos momentos tu cuenta no pudo ser creada, por favor intente más tarde 01')
      }
    }
    localStorage.setItem('token', res.token)
    setIsAuth(true)
    setMessage('¡Tu cuenta ha sido creada!, Ingresando...')
    setTimeout(() => {
      setLoad(false)
      auth.signin(res.data, () => {
        navigate('/')
      })
    }, 2000)
  }


  const isPersona = userType === 'persona'
  return (
    <Grid container spacing={3}>
      <Grid item container xs={12}>
        <RadioGroup value={userType} defaultValue={'1'} onChange={(e) => setUserType(e.target.value)}>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <FormControlLabel control={<Radio defaultChecked />} label="Persona" value={'persona'} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel control={<Radio />} label="Empresa" value={'empresa'} />
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={
            isPersona ? 'Ingresa tu RUT' : 'Ingresa el RUT empresa'}
          fullWidth
          value={rut}
          onChange={(e) => setRut(e.currentTarget.value)}
        />
      </Grid>
      {isPersona && <Grid item md={6} className={hideOnMobile}></Grid>}
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={isPersona ? 'Nombres' : 'Razón social'}
          fullWidth
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
      </Grid>
      {isPersona &&
        <Grid item xs={12} md={6}>
          <TextField
            placeholder='Apellidos'
            fullWidth
            value={last_name}
            onChange={(e) => setLast_name(e.currentTarget.value)}
          />
        </Grid>}
      <Grid item xs={12} md={6}>
        <TextField
          placeholder='Correo'
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder='Repite correo'
          fullWidth
          value={reEmail}
          onChange={(e) => setReEmail(e.currentTarget.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder='Crea contraseña nueva'
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          type="password"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder='Repite contraseña creada'
          fullWidth
          value={rePassword}
          onChange={(e) => setRePassword(e.currentTarget.value)}
          type="password"
        />
      </Grid>
      <Grid item xs={12} container justifyContent={'center'}>
        <Button onClick={onSubmit} type='secondary' style={{ minWidth: 200 }}><b>Entrar</b></Button>
      </Grid>
      {
        error && <Typography variant='body1' color="red" mt={3} style={{ width: '100%', textAlign: 'center' }}>
          {error}</Typography>
      }
      <Grid item xs={12} container justifyContent={'center'}>
        <Link to={'/login'}>
          <Typography variant='body1'>Ya tengo cuenta</Typography>
        </Link>
      </Grid>
    </Grid>
  )
}

export default Form