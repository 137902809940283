import React from 'react'
import { Root, RootItem, rootItemActive } from './index.style'
import { useLocation, useNavigate } from 'react-router-dom'

const Menu = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const pathname = location.pathname

    const handleGetActive = (path, secondPath) => {
        if (pathname === path) {
            return rootItemActive
        } else if (pathname === secondPath) {
            return rootItemActive
        } else {
            return null
        }
    }
    return (
        <Root>
            <RootItem onClick={() => navigate('/')} className={handleGetActive('/', '/property')}>
                <span>PROPIEDADES</span>
            </RootItem>
            <RootItem onClick={() => navigate('/auction')} className={handleGetActive('/auction', null)}>
                <span>REMATES</span>
            </RootItem>
            <RootItem onClick={() => navigate('/offerProperty')} className={handleGetActive('/offerProperty', null)}>
                <span>OFRECE TU PROPIEDAD</span>
            </RootItem>
            <RootItem onClick={() => navigate('/otherServices')} className={handleGetActive('/otherServices', null)}>
                <span>OTROS SERVICIOS</span>
            </RootItem>
            <RootItem onClick={() => navigate('/freeContracts')} className={handleGetActive('/freeContracts', null)}>
                <span>CONTRATOS GRATIS</span>
            </RootItem>
            <RootItem onClick={() => navigate('/propertyAgent')} className={handleGetActive('/propertyAgent', null)}>
                <span>AGENTE INMOBILIARIO</span>
            </RootItem>
        </Root>
    )
}

export default Menu