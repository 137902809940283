import { maxSizeMackbook, maxSizeMobile, maxSizeTablet, maxSizeTv } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
   padding-bottom: 30px;
`

export const RootImage = styled.div`
   height: 0px;
   padding-bottom: 79%;
   background-position: center;
   background-size: cover;
`
export const RootCard = styled.div`
   background-color: white;
   box-shadow: 0px 4px 9px rgba(0,0,0,0.4);
   height: 0px;
   padding-bottom: 44%;
   margin-left: 3%;
   margin-right: 3%;
   margin-top: -70px;
   border: solid 10px #F9CB48; 
   border-radius: 24px;
   display: flex;
   position: relative;
   @media (max-width: ${maxSizeMackbook}) {
      padding-bottom: 38%;
   }
   @media (min-width: ${maxSizeMackbook}) {
      padding-bottom: 35% ;
   }
   @media (max-width: ${maxSizeTablet}) {
      padding-bottom: 35% ;
      border-width: 4px;
   }
   @media (max-width: ${maxSizeMobile}) {
      font-size: 10px;
   }
`
export const RootButton = styled.div`
      position: absolute;
      bottom: -30px;
      width: 100%;
      display: flex;
      justify-content: 'center';
      > div {
         margin: auto;
      }
      > div > div > div {
         height: 36px;
       
      }
      p {
         min-width: 160px;
         text-align: center;
         @media (max-width: ${maxSizeMobile}) {
            font-size: 18px;
         }
         @media (max-width: ${maxSizeTablet}) {
            font-size: 14px;
         }
      }
      @media (max-width: ${maxSizeTablet}) {
         > div > div {
            min-width: 150px !important;
         }
      }
`
export const RootBody = styled.div`
   display: flex;
   margin:15px;
   flex:1;
   > div:first-child {
      width: 100%;
      p {
         @media (max-width: ${maxSizeTablet}) {
            font-size: 12px;
         }
         @media (max-width: ${maxSizeMobile}) {
            font-size: 10px;
         }
      }
   }
`