import { Grid, Typography } from '@mui/material'
import React from 'react'
import ArrowUp from '../Icons/ArrowUp'

const UpButton = () => {

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div style={{cursor:'pointer'}} onClick={handleScrollToTop}>
    <Grid container alignItems={'center'}>
      <ArrowUp />
      <Typography variant='body1' ml={1}><b>Volver a arriba</b></Typography>
    </Grid>
    </div>
  )
}

export default UpButton