import React from 'react'
import { Root, RootContainer, RootRight, RootTitle, rootTitleWithRight } from './Card.style'
import { Typography } from '@mui/material'
import { maxSizeMobile, theme } from '@/theme'
import { css } from '@emotion/css'

interface Props {
    children: any
    maxWidth?: number
    withContainer?: boolean
    paddingVertical?: number
    title?: string
    paddingInterior?: number
    paddingInteriorMobile?: string
    withoutBorder?: boolean
    right?: any
    className?: string
    borderRadius?: number
    id?: any
}

const Card: React.FC<Props> = ({
    children,
    maxWidth,
    withContainer,
    paddingVertical,
    paddingInterior,
    paddingInteriorMobile,
    borderRadius,
    title,
    withoutBorder,
    right,
    className,
    id
}) => {

    const mobileStyle = css`
        @media (max-width: ${maxSizeMobile}) {
            padding: ${paddingInteriorMobile} !important;
        }
    `

    if (withContainer) {
        return (
            <RootContainer
                id={id}
                className={className}
                style={{
                    paddingTop: paddingVertical,
                    paddingBottom: paddingVertical,
                }}>
                <Root
                className={mobileStyle} 
                style={{
                    maxWidth,
                    padding: paddingInterior,
                    border: withoutBorder ? null : `solid 7px ${theme.palette.primary.main}`,
                    borderRadius: borderRadius ? borderRadius : 23
                }}>
                    <RootTitle className={right && rootTitleWithRight}>
                        <Typography variant='h4'><b>{title}</b></Typography>
                    </RootTitle>
                    <RootRight>
                        {right}
                    </RootRight>
                    {children}</Root>
            </RootContainer>
        )
    }

    return (
        <Root style={{
            maxWidth,
            borderRadius: borderRadius ? borderRadius : 23
        }}>{children}</Root>
    )
}

export default Card