import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './Layout/Layout'
import Login from './pages/login'
import Protected from './Layout/Protected/Protected'
import Home from './pages/home'
import Register from './pages/register'
import AboutUs from './pages/aboutUs'
import OfferProperty from './pages/offerProperty'
import OtherServices from './pages/otherServices'
import FreeContracts from './pages/freeContracts'
import PropertyAgent from './pages/propertyAgent'
import Property from './pages/property'
import Drawer from './modules/global/Drawer'
import Auction from './pages/auction'
import Question from './pages/questions'
import get from './api/users/get'
import token from './api/users/token'
import { useAuth } from './provider/AuthProvider'

const App = () => {
    const [isAuth, setIsAuth] = useState(null)
    const [isVerifyAuth, setIsVerifyAuth] = useState(false)

    const auth = useAuth()

    useEffect(() => {
        if (!isVerifyAuth) {
            setIsVerifyAuth(true)
            void verifyAuth()
        }
    }, [])

    const verifyAuth = async () => {
        const localToken = localStorage.getItem('token')
        if (localToken) {
            const res = await token({ token: localToken })
            if (!res) {
                return console.log('Error al verificar token')
            }
            if (!res.data) {
                setIsAuth(false)
                return console.log('Token Incorrecto')
            }
            setIsAuth(true)
            await handleObtainUser(res.data.id)

        } else {
            setIsAuth(false)
        }

    }

    const handleObtainUser = async (id) => {
        console.log("handleObtainUser", id)
        const resUser = await get(id)
        console.log("resUser", resUser)
        auth.signin(resUser.data, () => { })
    }

    return (
        <>
            <Routes>
                <Route element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path='login' element={<Login setIsAuth={setIsAuth} />} />
                    <Route path='register' element={<Register setIsAuth={setIsAuth} />} />
                    <Route path='property' element={<Property />} />
                    <Route path='property/:id' element={<Property />} />
                    <Route path='aboutUs' element={<AboutUs />} />
                    <Route path='auction' element={<Auction />} />
                    <Route path='offerProperty' element={<OfferProperty />} />
                    <Route path='otherServices' element={<OtherServices />} />
                    <Route path='freeContracts' element={<FreeContracts />} />
                    <Route path='propertyAgent' element={<PropertyAgent />} />
                    <Route path='questions' element={<Question />} />
                    <Route path="*" element={<p>No hay nada aquí: 404!</p>} />
                </Route>
            </Routes>
        </>
    )
}

export default App