import Card from '@/components/Cards/Card/Card'
import Form from '@/modules/register/Form'
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Root } from './index.style'

const Register = ({ setIsAuth }) => {
  return (
    <Root>
      <Card
        title={'Formulario de registro'}
        maxWidth={924}
        withContainer
        paddingInterior={50}
        paddingInteriorMobile={'50px 34px'}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='body1'>Selecciona una opción</Typography>
          </Grid>
        
          <Grid item xs={12}>
            <Form setIsAuth={setIsAuth} />
          </Grid>
        </Grid>
      </Card>
    </Root>
  )
}

export default Register