import React from 'react'
import AccordionMUI from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ChevronRight from '@/components/Icons/ChevronRight';
import { ButtonStyle, DogImage, IconExclamation, Root, RootButtonDog } from './index.style';
import { Button } from '@/components/Buttons/Button';
import DownloadIcon from '@/components/Icons/DownloadIcon';

const iconSrc = require('@/assets/images/icon-exclamation.webp')

const Accordion = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const BodyItem = ({
        title,
        description
    }) => (
        <Grid container spacing={4}>
            <Grid item xs={12} lg={6} mt={5}>
                <Typography variant='body1'>
                    {title}
                </Typography>
                <Typography variant='body2' mt={3}>
                    <IconExclamation src={iconSrc} />
                    {description}
                </Typography>
            </Grid>
            <Grid item container xs={12} lg={6} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <DogImage src={dogSrc} />
                <RootButtonDog>
                    <Button style={{}} type='outlined'>
                        <ButtonStyle>
                            <b>DESCARGA GRATIS</b> <DownloadIcon style={{ marginTop: 5, marginLeft: 10 }} />
                        </ButtonStyle>
                    </Button>
                </RootButtonDog>
            </Grid>
        </Grid>
    )

    const getBody = (i) => {
        switch (i) {
            case 1:
                return (
                    <BodyItem
                        title='Una vez que tu abogado o asesor inmobiliario modifique este contrato e individualice a las partes, tanto el arrendador, así como también el arrendatario deben firmarlo en notaría bajo escritura pública para formalizarlo.'
                        description='SeRemata SpA se libera de toda responsabilidad por cualquier mal uso, omisión de información, omisión de condiciones, conducta impropia de cualquier usuario que descargue el documento y lo modifique para los fines que estime conveniente. Este documento tiene carácter meramente informativo.'
                    />
                )
            case 2:
                return (
                    <BodyItem
                        title='Se recomienda que una vez que tu abogado o asesor inmobiliario modifique este contrato e individualice a las partes, tanto el arrendador, así como también el arrendatario deben firmar el documento en notaría bajo escritura pública para formalizarlo.'
                        description='SeRemata SpA se libera de toda responsabilidad por cualquier mal uso, omisión de información, omisión de condiciones, conducta impropia de cualquier usuario que descargue el documento y lo modifique para los fines que estime conveniente. Este documento tiene carácter meramente informativo.'
                    />
                )
            case 3:
                return (
                    <BodyItem
                        title='Se recomienda que una vez que tu abogado o asesor inmobiliario modifique este anexo, tanto el arrendador, así como también el arrendatario deben firmarlo en notaría bajo escritura pública junto a su respectivo contrato.'
                        description='SeRemata SpA se libera de toda responsabilidad por cualquier mal uso, omisión de información, omisión de condiciones, conducta impropia de cualquier usuario que descargue el documento y lo modifique para los fines que estime conveniente. Este documento tiene carácter meramente informativo.'
                    />
                )
            case 4:
                return (
                    <BodyItem
                        title='Se recomienda que una vez que tu abogado o asesor inmobiliario modifique este contrato e individualice a las partes, tanto el promitente comprador, así como también el promitente vendedor deben firmarlo en notaría bajo escritura pública para formalizarlo. Es preciso que ambas partes se asesoren, puesto que este documento contiene cláusula penal proforma.'
                        description='SeRemata SpA se libera de toda responsabilidad por cualquier mal uso, omisión de información, omisión de condiciones, conducta impropia de cualquier usuario que descargue el documento y lo modifique para los fines que estime conveniente. Este documento tiene carácter meramente informativo.'
                    />
                )
            case 5:
                return (
                    <BodyItem
                        title='Se recomienda que una vez que tu abogado o asesor inmobiliario modifique este mandato e individualice a las partes, deberán firmarlo en notaría bajo escritura pública para formalizarlo. Revisa el alcance de este documento junto a un especialista, ya que un mandato de este tipo entrega diversas facultades a otra persona (legales, comerciales, financieras, etc).'
                        description='SeRemata SpA se libera de toda responsabilidad por cualquier mal uso, omisión de información, omisión de condiciones, conducta impropia de cualquier usuario que descargue el documento y lo modifique para los fines que estime conveniente. Este documento tiene carácter meramente informativo.'
                    />
                )
            case 6:
                return (
                    <BodyItem
                        title='Se recomienda que una vez que tu abogado o asesor inmobiliario modifique este contrato e individualice a las partes, deberán firmarlo en notaría bajo escritura pública para formalizarlo. El mandatario con es quien firma como representante del dueño. Revisa el alcance de las facultades entregadas a tu representante en el mandato de administración respectivo; debe tener facultades para firmar contrato de arriendo.'
                        description='SeRemata SpA se libera de toda responsabilidad por cualquier mal uso, omisión de información, omisión de condiciones, conducta impropia de cualquier usuario que descargue el documento y lo modifique para los fines que estime conveniente. Este documento tiene carácter meramente informativo.
                        '
                    />
                )
            case 7:
                return (
                    <BodyItem
                        title='Se recomienda que el agente de propiedades y su cliente firmen este contrato de corretaje en notaría bajo escritura pública. Revisa la cantidad de meses de vigencia para el presente contrato proforma y el porcentaje de cobro por parte del agente inmobiliario.'
                        description='SeRemata SpA se libera de toda responsabilidad por cualquier mal uso, omisión de información, omisión de condiciones, conducta impropia de cualquier usuario que descargue el documento y lo modifique para los fines que estime conveniente. Este documento tiene carácter meramente informativo..'
                    />
                )
            case 8:
                return (
                    <BodyItem
                        title='Se recomienda que el agente de propiedades y su cliente firmen este contrato de corretaje exclusivo en notaría bajo escritura pública. Revisa la cantidad de meses de vigencia para el presente contrato proforma.'
                        description='SeRemata SpA se libera de toda responsabilidad por cualquier mal uso, omisión de información, omisión de condiciones, conducta impropia de cualquier usuario que descargue el documento y lo modifique para los fines que estime conveniente. Este documento tiene carácter meramente informativo.'
                    />
                )
            case 9:
                return (
                    <BodyItem
                        title='Se recomienda que una vez que tu abogado o asesor inmobiliario modifique este documento e individualice a las partes, firmen en notaría este instrumento privado para su respaldo.'
                        description='SeRemata SpA se libera de toda responsabilidad por cualquier mal uso, omisión de información, omisión de condiciones, conducta impropia de cualquier usuario que descargue el documento y lo modifique para los fines que estime conveniente. Este documento tiene carácter meramente informativo.
                        '
                    />
                )
            case 10:
                return (
                    <BodyItem
                        title='Este modelo de check list facilita la interacción con tu cliente, logrando abordar consultas relevantes sobre la propiedad que publicitarás. ¡Modifica este documento y ordénalo a tu gusto!. Hagamos crecer este marketplace entre todos.'
                        description='SeRemata SpA se libera de toda responsabilidad por cualquier mal uso, omisión de información, omisión de condiciones, conducta impropia de cualquier usuario que descargue el documento y lo modifique para los fines que estime conveniente. Este documento tiene carácter meramente informativo.                        '
                    />
                )
            default:
                break;
        }
    }

    const dogSrc = require('@/assets/images/dog.webp')
    return (
        <>
            {
                [
                    '1) Contrato arriendo inmueble habitacional no amoblado',
                    '2) Contrato arriendo inmueble habitacional amoblado',
                    '3) Anexo de contrato para inventario en arriendos amoblados',
                    '4) Contratos de promesa de compraventa de inmueble',
                    '5) Mandato de administarción amplia',
                    '6) Contrato de arriendo con mandatario',
                    '7) Contrato de corretaje con administración de inmueble ',
                    '8) Contrato de corretaje exclusivo',
                    '9) Carta poder',
                    '10) Check list para agentes de propiedades',
                ].map((i, key) => (
                    <Root>
                        <AccordionMUI expanded={expanded === 'panel' + key} onChange={handleChange('panel' + key)}>
                            <AccordionSummary
                                expandIcon={<ChevronRight />}
                                aria-controls="panel1-content"
                                id="panel1-header">
                                <Typography variant='body1'>
                                    <b>{i}</b>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    getBody(key + 1)
                                }
                            </AccordionDetails>
                        </AccordionMUI>
                    </Root>
                ))
            }
        </>
    )
}

export default Accordion