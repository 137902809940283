import styled from "@emotion/styled";

export const Root = styled.div`
    padding-top: 2%;
    padding-bottom: 2%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 60px;
    cursor:pointer;
    transition: all 0.2s;
    > p {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; 
    }
`