import React from 'react'
import AccordionMUI from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ChevronRight from '@/components/Icons/ChevronRight';
import { ButtonStyle, DogImage, Root, RootBody, RootButtonDog } from './index.style';
import { Button } from '@/components/Buttons/Button';
import DownloadIcon from '@/components/Icons/DownloadIcon';

const Accordion = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const dogSrc = require('@/assets/images/dog.webp')

    const getBody = (i) => {
        switch (i) {
            case 1:
                return (
                    <ul>
                        <li><Typography variant='body1'>Crea una cuenta como persona natural o jurídica.</Typography></li>
                        <li><Typography variant='body1'>Añade tus datos en nuestra plataforma.</Typography></li>
                        <li><Typography variant='body1'>Explora la billetera virtual o <b>“wallet”</b>.</Typography></li>
                        <li><Typography variant='body1'>¿Te animas a seguir aprendiendo de remates?. Únete a nuestras redes sociales <a className='aDefault'>aquí.</a></Typography></li>
                    </ul>
                )
            case 2:
                return (
                    <ul>
                        <li><Typography variant='body1'>Revisa los antecedentes de la propiedad descargables.</Typography></li>
                        <li><Typography variant='body1'>Revisa las condiciones del remate descargables.</Typography></li>
                        <li><Typography variant='body1'>Si ya le echaste el ojo a una propiedad en remate y no quieres esperar a la puja,
                            oprime el ícono de <b>“venta directa”</b> y envía tu oferta. Solo este sistema permite evaluar
                            un posible hipotecario sobre un inmueble.</Typography></li>
                        <li><Typography variant='body1'><u><b>Las propiedades subastadas en línea únicamente permiten adjudicaciones con efectivo</b></u>,
                            por lo que también puedes esperar el día de la subasta en vivo</Typography></li>
                    </ul>
                )
            case 3:
                return (
                    <ul>
                        <li>
                            <Typography variant='body1'>
                                Paga la <b><u>garantía mínima</u></b> de 3 maneras:
                            </Typography>
                            <Typography variant='body1' style={{ marginLeft: 20, marginTop: -10 }}>
                                <br />
                                A) Con transferencia bancaria o vale vista.
                                <br />
                                B) Botones de pago.
                                <br />
                                C) Con tu wallet de Mercado Pago.
                            </Typography>
                            </li>
                        <li style={{ marginTop: 20 }}>
                            <Typography variant='body1'>
                                Paga la <b><u>diferencia de adjudicación</u></b> de 2 maneras:
                            </Typography>
                            <Typography variant='body1' style={{ marginLeft: 20, marginTop: -10 }}>
                                <br />
                                A) Con transferencia bancaria o vale vista.
                                <br />
                                B) Con tu wallet de Mercado Pago.
                            </Typography>
                            </li>
                        <li style={{ marginTop: 20 }}>
                            <Typography variant='body1'>
                                Paga los <b><u>costos de gestión e inscripción</u></b> de 2 maneras:
                            </Typography>
                            <Typography variant='body1' style={{ marginLeft: 20, marginTop: -10 }}>
                                <br />
                                A) Con transferencia bancaria o vale vista.
                                <br />
                                B) Con tu wallet de Mercado Pago.
                            </Typography>
                            </li>
                    </ul>
                )
            case 4:
                return (
                    <ul>
                        <li><Typography variant='body1'>La garantía mínima es de $3.000.000, una vez verificada, ya tienes un cupo para participar en subasta.</Typography></li>
                        <li><Typography variant='body1'>Trabajamos con Mercado Pago, sistema virtual de Mercadolibre.</Typography></li>
                        <li><Typography variant='body1'>Wallet cifrado que te permite pagar garantías, saldos de adjudicaciones y costos de gestión.</Typography></li>
                        <li><Typography variant='body1'>Tu tiempo, tus lucas. Puedes rescatar el dinero de tu wallet en cualquier momento y lugar.</Typography></li>
                        <li><Typography variant='body1'>Haz el seguimiento de saldos históricos, así como también de abonos y cargos de tu wallet.</Typography></li>
                        <li><Typography variant='body1'>Puedes mantener el saldo que quieras en tu wallet, sin tope.</Typography></li>
                        <li><Typography variant='body1'>Recuerda que tus lucas tienen rentabilidad diaria. ¡Mejor que dejarlo en la cuenta corriente!.</Typography></li>
                    </ul>
                )
            case 5:
                return (
                    <Grid container>
                        <Grid item xs={9}>
                            <ul>
                                <li><Typography variant='body1'><u><b>“Participante”</b></u> es la persona natural o jurídica que pagó su garantía mínima de $3.000.000, quien podrá pujar por propiedades.</Typography></li>
                                <li><Typography variant='body1'><u><b>“Observador”</b></u>, es la persona por ahora no pujará pero puede seguir el remate en vivo.</Typography></li>
                                <li><Typography variant='body1'>Únete a la subasta en línea pinchando el ícono amarillo con rojo en la parte superior del portal.</Typography></li>
                                <li><Typography variant='body1'>Si lograste adjudicarte el lote que querías, tu garantía se descontará del saldo total de adjudicación, el cual 
                                deberás pagar en un plazo no mayor de 25 días corridos. Ejemplo: Garantía mínima $3.000.000, menos el valor total del lote adjudicado $23.000.000; diferencia de adjudicación $20.000.000.</Typography></li>
                                <li><Typography variant='body1'>Si en esta ocasión no ganaste la puja, tanqui, tranqui, la garantía mínima se devuelve en un 100%.</Typography></li>
                            </ul>
                        </Grid>
                        {/* <Grid item xs={3} container justifyContent={'center'} alignItems={'center'}>
                            <Button type='outlined' style={{height: 30}}>SUBASTA EN VIVO</Button>
                        </Grid> */}
                    </Grid>
                )
            case 6:
                return (
                    <ul>
                        <li><Typography variant='body1'>¡Ya estás en camino para aumentar tu patrimonio!. Si ganaste la subasta, recibirás un correo electrónico con
                            tú <u><b>número de adjudicación.</b></u></Typography></li>
                        <li><Typography variant='body1'>Paga la diferencia de adjudicación <u>indicando tu número de adjudicación.</u></Typography></li>
                        <li><Typography variant='body1'>Paga los costos de gestión e inscripción <u>indicando tu número de adjudicación.</u></Typography></li>
                        <li><Typography variant='body1'>Una vez que se verifican los pagos, un especialista de nuestra área legal te contactará.</Typography></li>
                    </ul>
                )
            case 7:
                return (
                    <ul>
                        <li><Typography variant='body1'><u><b>¡Felicidades!, ya tienes un un nuevo inmueble.</b></u></Typography></li>
                        <li><Typography variant='body1'>Un especialista de nuestra empresa te remitirá toda la información legal y dará aviso sobre la culminación del proceso vía telefónica.</Typography></li>
                        <li><Typography variant='body1'>En tu portal SeRemata® encontrarás un historial de adjudicaciones.</Typography></li>
                    </ul>
                )
            case 8:
                return (
                    <ul>
                        <li><Typography variant='body1'>Revisa el módulo de “otros servicios” a nivel nacional <a className='aDefault'><u>aquí.</u></a></Typography></li>
                        <li><Typography variant='body1'>Si eres inversionista y necesitas otro tipo de servicios profesionales, escríbenos para enviar presupuesto de tasación, fotografías aéreas, testamentos, contratos, asesoría tributaria, entre otros.</Typography></li>
                        <li><Typography variant='body1'><u>Si quieres aprender a invertir por tu cuenta en remates judiciales,</u> puedes comprar el curso completo en el módulo de “otros servicios”.</Typography></li>
                    </ul>
                )
            default:
                break;
        }
    }

    return (
        <>
            {
                [
                    '1) Crea tu cuenta',
                    '2) Encuentra la propiedad y conoce sus características',
                    '3) Conoce las formas de pago',
                    '4) Pagar garantía mínima',
                    '5) ¡Participa en la subasta y adjudícate la propiedad!',
                    '6) ¡Ya ganaste!. Paga tu adjudicación lo antes posible',
                    '7) ¡Listo!. Recibe tu escritura y certificados',
                    '8) ¿Otros servicios para tu nueva propiedad?'
                ].map((i, key) => (
                    <Root>
                        <AccordionMUI expanded={expanded === 'panel' + key} onChange={handleChange('panel' + key)}>
                            <AccordionSummary
                                expandIcon={<ChevronRight />}
                                aria-controls="panel1-content"
                                id="panel1-header">
                                <Typography variant='body1'>
                                    <b>{i}</b>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RootBody>
                                {
                                    getBody(key + 1)
                                }
                                </RootBody>
                            </AccordionDetails>
                        </AccordionMUI>
                    </Root>
                ))
            }
        </>
    )
}

export default Accordion