import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Typography } from '@mui/material'
import { Root, RootDogHouse, RootItem, RootItemTitle } from './Carrousel.style'

const sliderSrc1 = require('@/assets/images/carrousel/slider-invite-seremata.webp')
const sliderSrc2 = require('@/assets/images/carrousel/slider-concurso-seremata.webp')
const sliderSrc3 = require('@/assets/images/carrousel/slider-propiedades-seremata.webp')

const Carrousel = () => {
    var items = [
        {
            name: <Typography variant='h2'>Lorem ipsum dolor sit amet, consectetur <span style={{ color: '#F9CB48' }}>adipiscing elit.</span></Typography>,
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            source: sliderSrc1
        },
        {
            name: <Typography variant='h2'>Lorem ipsum dolor sit amet, consectetur <span style={{ color: '#F9CB48' }}>adipiscing elit.</span></Typography>,
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            source: sliderSrc2
        },
        {
            name: <Typography variant='h2'>Lorem ipsum dolor sit amet, consectetur <span style={{ color: '#F9CB48' }}>adipiscing elit.</span></Typography>,
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            source: sliderSrc3
        },
    ]
    return (
        <Root>
            <Carousel
                swipe={false}
                animation='slide'
                navButtonsAlwaysVisible>
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
        </Root>
    )
}
function Item(props) {
    return (
        <RootItem>
            {/* <RootItemTitle>
                {props.item.name}
                <Typography variant='h5'>{props.item.description}</Typography>
            </RootItemTitle> */}
            <RootDogHouse style={{ 
                backgroundImage: `url(${props.item.source})` 
                }}>
            </RootDogHouse>
        </RootItem>
    )
}
export default Carrousel