
import { maxSizeMobile } from "@/theme";
import styled from "@emotion/styled";


export const RootTitle = styled.div`
    margin-bottom: 20px;
    text-align: center;
  > div {
      display: flex;
      align-items: center;
      justify-content: center;
    > h3 {
      margin-right:20px;
    }
  }
`
export const IconImage = styled.img`
  width: 50px;
  margin-left: 10px;
`
export const LogoPayment = styled.div`
  width: 90%;
  margin:auto;
  padding-bottom: 90%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
export const RootButton = styled.div`
    width: 100%;
    display: flex;
    margin-top: 10px;
    > div  {
      width: 100%;
    }
`
export const RootPayments = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  > div {
    flex:1;
    margin: 10px;
  }
  @media (max-width: ${maxSizeMobile}) {
     flex-direction:  column;
  }
`