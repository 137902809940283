
import styled from "@emotion/styled";

export const CaraSonriendoImg = styled.img`
  width: 70px;
  margin-left: 4px;
`
export const RootTitle = styled.div`
    margin-bottom: 20px;
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    > h3 {
      margin-right:20px;
    }
`