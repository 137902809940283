import { maxSizeTablet, theme } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    background-color: ${theme.palette.primary.main};
    width:100%;
    height: 50px;
    display: flex;
    @media (max-width: ${maxSizeTablet}) {
      display: none;
   }
    
`
export const RootItem = styled.button`
    text-align: center;
    flex:1;
    :hover {
        opacity: 0.4;
    }
    :active {
        opacity: 0.9;
        background-color: rgba(0,0,0,0.1)
    }
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-style: inherit;
    background: inherit;
    font-weight: bold;
    position: relative;
`
export const rootItemActive = css`
   ::before {
        content: '';
        width: 100%;
        height: 4px;
        background-color: black;
        position: absolute;
        bottom:0px;
   }
`
