import { createTheme } from "@mui/material";
import { inherits } from "util";

export const maxSizeTvNum = 2000
export const maxSizeMackbookNum = 1723
export const maxSizeTabletNum = 1024
export const maxSizeMobileNum = 768

export const maxSizeTv = maxSizeMackbookNum + 'px'
export const maxSizeMackbook = maxSizeMackbookNum + 'px'
export const maxSizeTablet = maxSizeTabletNum + 'px'
export const maxSizeMobile = maxSizeMobileNum + 'px'


export const theme = createTheme({
  typography: {
    fontSize: 18,
    fontFamily: [
      'LeagueSpartan',
      'sans-serif',
    ].join(',')
  },
  palette: {
    primary: {
      main: '#F9CB48',
      dark: '#F9CB48'
    },
    secondary: {
      main: '#2D2D2D',
      dark: '#2D2D2D'
    },
    background: {
      default: '#F9CB48'
    },
    warning: {
      main: '#F9CB48'
    }
  }
});

theme.typography.h1 = {
  fontSize: 60,
  fontWeight: 700,
  letterSpacing: -3,
}
theme.typography.h2 = {
  fontSize: 50,
  fontWeight: 700,
}
theme.typography.h3 = {
  fontWeight: 500,
  fontSize: 38,
  lineHeight: 1.15,
  [theme.breakpoints.down('sm')]: {
    fontSize: 24,
  },
}
theme.typography.h4 = {
  fontSize: 30,
  fontWeight: 500,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}

theme.typography.h5 = {
  fontSize: 26,
  fontWeight: 'normal',
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}

theme.typography.h6 = {
  fontSize: 22,
  fontWeight: 'inherit',
}


theme.typography.subtitle1 = {
  fontSize: 18,
  fontWeight: 'normal',
}

theme.typography.subtitle2 = {
  fontSize: 22,
  fontWeight: 'initial',
}

theme.typography.caption = {
  fontSize: 12,
  letterSpacing: 1.2,
}

theme.typography.body1 = {
  fontSize: 20,
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}

theme.typography.body2 = {
  fontSize: 19,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}