import React from 'react'
import { Table as TableMUI, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { tableCell, tableCellHead, tableHead, tableRoot, tableRow } from './index.style'

const Table = () => {
    return (
        <TableMUI className={tableRoot}>
            <TableHead className={tableHead}>
                <TableRow className={tableRow}>
                    <TableCell className={tableCellHead}>
                        <Typography variant='body1'><b>DATOS</b></Typography>
                    </TableCell>
                    <TableCell className={tableCellHead}>
                        <Typography variant='body1'><b>PAGO GARANTÍA</b></Typography>
                    </TableCell>
                    <TableCell className={tableCellHead}>
                        <Typography variant='body1'><b>PAGO ADJUDICACIÓN</b></Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow className={tableRow}>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'><b>Nombre</b></Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'>Se Remata SPA</Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'>Se Remata SPA</Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={tableRow}>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'><b>RUT</b></Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'>7X.XXX.XXX-X</Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'>7X.XXX.XXX-X </Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={tableRow}>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'><b>Banco</b></Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'>Banco de Chile</Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'>Banco de Chile </Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={tableRow}>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'><b>Cuenta Corriente</b></Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'>XXXXXXXXXX1</Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'>XXXXXXXXXX2</Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={tableRow}>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'><b>Correo Electrónico</b></Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'>garantía@seremata.cl </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                        <Typography variant='body2'>saldo@seremata.cl </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </TableMUI>
    )
}

export default Table