
import { maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
   margin-left: 18px;
   margin-right: 18px;
   margin-top: 50px;
`
