import React from 'react'
import DrawerMUI from '@mui/material/Drawer';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Drawer = ({ open, toggleDrawer }) => {
    const navigate = useNavigate()
    return (
        <div>
            <DrawerMUI
                anchor='right'
                open={open}
                onClose={() => toggleDrawer(false)}>
                <Box sx={{ width: 250 }} role="presentation" onClick={() => toggleDrawer(false)}>
                    <List>
                        <ListItem>
                            <ListItemText onClick={() => navigate('/')}>
                                <span>PROPIEDADES</span>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText onClick={() => navigate('/auction')}>
                                <span>REMATES</span>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText onClick={() => navigate('/offerProperty')}>
                                <span>OFRECE TU PROPIEDAD</span>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText onClick={() => navigate('/otherServices')}>
                                <span>OTROS SERVICIOS</span>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText onClick={() => navigate('/freeContracts')}>
                                <span>CONTRATOS GRATIS</span>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText onClick={() => navigate('/propertyAgent')}>
                                <span>AGENTE DE PROPIEDADES</span>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Box>
            </DrawerMUI>
        </div >
    )
}

export default Drawer