import Card from '@/components/Cards/Card/Card'
import Form from '@/modules/login/Form'
import React, { useEffect } from 'react'
import { Root } from './index.style'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/provider/AuthProvider'

const Login = ({ setIsAuth }) => {
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    if (auth.user) {
      navigate('/')
    }
  }, [auth])

  return (
    <>
      <Root>
        <Card
          title={'Iniciar sesión'}
          maxWidth={524}
          withContainer
          paddingInterior={50}
          paddingInteriorMobile={'50px 34px'}>
          <Form setIsAuth={setIsAuth} />
        </Card>
      </Root>
    </>
  )
}

export default Login