import { maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    flex-direction: row;
    display: flex;
    align-items: flex-end;;
`

export const RootItem = styled.div`
    background-color: #F1F1F1;
    height: 40px;
    padding: 0px 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transition: all 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: ${maxSizeMobile}) {
        padding: 0px;
        width: 100%;
    }
    > p {
        font-size: 16px;
    }
    > p {
        @media (max-width: ${maxSizeMobile}) {
            font-size: 12px;
        }
    }
`
export const activeClass = css`
    background-color: #D9D9D9 !important;
    > p {
        font-weight: bold !important;
        margin:0px !important;
        padding: 0px !important;
    }
`