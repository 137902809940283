import React from 'react'
import Carrousel from './Carrousel/Carrousel'
import BodyList from './BodyList/BodyList'
import { RootBodyList } from './Body.style'

const Body = () => {
  return (
    <div>
      <Carrousel />
      <RootBodyList>
        <BodyList />
      </RootBodyList>
    </div>
  )
}

export default Body