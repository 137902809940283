import { css } from '@emotion/css'
import React from 'react'
import { theme } from '@/theme'
import { Root } from './Button.style'
import { Typography } from '@mui/material'

interface Props {
    type?: 'default' | 'primary' | 'outlined' | 'secondary'
    children: any
    style?: any
    onClick?: any
    className?: any
    padding?: any
}

export const Button: React.FC<Props> = ({
    type,
    children,
    padding,
    className,
    ...props
}) => {

    const typeRootStyle = () => {
        switch (type || 'default') {
            case 'primary':
                return css``
            case 'secondary':
                return css`
                    height: 70px;
                    padding-top: 20px;
                    padding-right: 17px;
                `
            case 'outlined':
                return css`
                `
            default:
                return css``
                break;
        }
    }

    const typeRootChildStyle = () => {
        switch (type || 'default') {
            case 'primary':
                return css`
                    background:  ${theme.palette.primary.main};
                    > p {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                `
            case 'secondary':
                return css`
                    background:  ${theme.palette.secondary.main};
                    position: relative;
                    height: 36px;
                    ::after {
                        content: '';
                        border: 1px solid ${theme.palette.secondary.main};
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                        position: absolute;
                        top: 7px;
                        left: 7px;
                        border-radius: 60px;
                        transition: all 0.2s;
                    }
                    :hover {
                        margin-top: 5px;
                        margin-left: 5px;
                    }
                    :hover::after {
                        top: 3px;
                        left: 3px;
                    }
                    :active {
                        margin-top: 9px;
                        margin-left: 9px;
                    }
                    :active::after {
                        top: -1px;
                        left: -1px;
                    }
                `
            case 'outlined':
                return css`
                    background: ${theme.palette.background.default} ;
                    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='25' ry='25' stroke='black' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
                    border-radius: 25px;
                    height: 0px;
                    
                    > p {
                        padding-left: 25px;
                        padding-right: 25px;
                        padding: ${padding ? padding : 'inherit'}
                    }
                    :hover {
                        transform: scale(1.1);
                    }
                    :active {
                        transform: scale(0.9);
                    }
                `
            default:
                return css``
                break;
        }
    }

    const typeTextStyle = () => {
        switch (type || 'default') {
            case 'primary':
                return css`
                    color: black;
                `
            case 'secondary':
                return css`
                    color: white;
                `
            case 'outlined':
                return css`
                    color: black ;
                 
                `
            default:
                return css``
                break;
        }
    }

    return (
        <div className={typeRootStyle() + ' ' + className}>
            <Root {...props} className={typeRootChildStyle()}>
                <Typography variant='body2' className={typeTextStyle()}>{children}</Typography>
            </Root>
        </div>
    )
}
