import Card from '@/components/Cards/Card/Card'
import Form from '@/modules/offerProperty/Form'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { CaraSonriendoImg, RootTitle } from './index.style'

const OfferProperty = () => {
  const caraSonriendoSrc = require('@/assets/images/cara_sonriendo_seremata.webp')
  return (
    <div>
      <Grid container pt={5} pl={5} pr={5} maxWidth={1000} margin={'auto'} mt={3}>
        <Grid item xs={12}>
          <RootTitle>
            <Typography variant='h4'>
              <b>¿Tienes alguna propiedad en venta?</b>
            </Typography>
            <div>
              <Typography variant='h5'>
                ¡Dale po!, conversemos
              </Typography>
              <CaraSonriendoImg src={caraSonriendoSrc} />
            </div>
          </RootTitle>
          <Typography variant='body1' align='center' mt={5}>
            ¡Atrévete a dar el salto con nuestro equipo para tener ofertas por tu propiedad en poco tiempo!.
            <br />
            <br />
            <b>Si realmente quieres vender tu propiedad,</b> completa el formulario y conversa directamente con un ejecutivo.
            Nosotros te apoyaremos con la venta del inmueble, pero debes saber que en nuestra empresa 
            {' '}<u><b>“Se Remata” a un valor comercial castigado,</b></u>{' '}
            publicando siempre a un precio de venta inferior al indicado en la tasación.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card paddingInterior={60} paddingVertical={100} title='Completa el formulario' withContainer>
            <Form />
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default OfferProperty