import UpButton from '@/components/UpButton/UpButton'
import Table from '@/modules/propertyAgent/Table'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { IconImage, RootTitle } from './index.style'

const PropertyAgent = () => {
  const iconImageSrc = require('@/assets/images/hand-house-seremata.webp')
  return ( 
    <div>
      <Grid container pt={5} pl={5} pr={5} maxWidth={1050} margin={'auto'} mt={3}>
        <Grid item xs={12} container justifyContent={'center'}>
          <RootTitle>
            <div>
              <Typography variant='h4'>
                <b>Portal de agentes inmobiliarios</b>
              </Typography>
              <IconImage src={iconImageSrc} />
            </div>
            <Typography variant='h5'>Un portal para todos: integrando a personas comprometidas</Typography>
          </RootTitle>
          <Typography variant='body1' mt={9} align='center'>
            <b>Somos el primer portal en Chile que integra y posiciona gratuitamente a agentes inmobiliarios</b>
            <br /><br />
            ¿Qué nos hace diferentes?. Nuestro enfoque colaborativo e innovativo. Este portal se ha creado desde cero, sin seguir plantillas ni formatos predefinidos puesto que buscábamos algo único; tener un portal flexible que permita escuchar la voz de sus clientes y de su comunidad, permitiendo así la interacción entre compradores, vendedores y agentes inmobiliarios.
            <br /><br />
            <b><u>¿Agencia inmobiliaria o corredor independiente?.</u></b>
            <br /><br />
            <b>!Esta es una excelente opción de canje!.</b>
            <br /><br />
            No existen cobros asociados por publicar. <br />
            No realizamos contrato de venta con tu cliente. <br />
            Tendrás una mayor visibilidad de tus propiedades. <br />
            Sistema de reputación para agentes de propiedades. <br />
            Derivación de potenciales clientes según tu zona geográfica. <br />
            Se subastan propiedades que tengan un valor comercial castigado.<br />
          </Typography>
        </Grid>
        <Grid item xs={12} mt={9} container justifyContent={'center'}>
          <Typography variant='body1'><b>TOP 15 Agentes con mejores calificaciones</b></Typography>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Table />
        </Grid>
        <Grid item xs={12} mt={6}>
          <UpButton />
        </Grid>
      </Grid>
    </div>
  )
}

export default PropertyAgent