import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { Root, RootStart } from './TextFieldPhone.style'

const TextFieldPhone = (props: TextFieldProps) => {
    return (
        <Root>
            <TextField
                inputProps={{
                    maxLength: 9
                }}
                {...props}
                InputProps={{
                    startAdornment: <RootStart>
                        <InputAdornment position="start">+56</InputAdornment>
                    </RootStart>,
                }}
            />
        </Root>
    )
}

export default TextFieldPhone