import { Typography } from '@mui/material'
import React from 'react'
import { Root, RootItem, activeClass } from './Tabs.style'

interface Props {
    names: Array<string>
    value?: string
    setValue?: any
}

const Tabs = ({
    names,
    value,
    setValue 
}: Props) => {
    return (
        <Root>
            {
                names.map(i => {
                    const isValue = i === value
                    return (
                        <RootItem 
                            className={isValue && activeClass}
                        onClick={() => setValue(i)}>
                            <Typography variant='body2'>{i}</Typography>
                        </RootItem>
                    )
                })
            }
        </Root>
    )
}

export default Tabs