import { Button } from '@/components/Buttons/Button'
import TextFieldPhone from '@/components/Inputs/TextFieldPhone/TextFieldPhone'
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'

const Form = () => {
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} mt={2}>
                    <Typography variant='body1' mb={1}>Datos de contacto</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        placeholder='Nombres'
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        placeholder='Apellidos'
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        placeholder='Correo electrónico'
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextFieldPhone
                        placeholder='Teléfono'
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        placeholder='Si tienes alguna consulta puedes escribirla aquí. (Máximo 250 caracteres)'
                        fullWidth
                        type='text'
                        multiline
                        minRows={4}
                        inputProps={{
                            maxLength: 250
                        }}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent={'center'}>
                    <Button type='secondary' style={{ minWidth: 250 }}><b>Enviar</b></Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default Form