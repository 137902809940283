import React from 'react'
import Item from './Item/Item'
import { Grid } from '@mui/material'

const List = ({ properties }) => {
    return (
        <div>
            <Grid container spacing={2}>
                {
                    properties.map((i, index) => (
                        <Grid item xs={12} md={6} lg={4}>
                            <Item item={i} />
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    )
}

export default List