
import React from 'react'
import AccordionMUI from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ChevronRight from '@/components/Icons/ChevronRight';
import { ButtonStyle, DogImage, Root, RootButtonDog } from './index.style';
import { Button } from '@/components/Buttons/Button';
import DownloadIcon from '@/components/Icons/DownloadIcon';

const AccordionGarantias = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const dogSrc = require('@/assets/images/dog.webp')

    const getBody = (i) => {
        switch (i) {
            case 1:
                return (
                    <ul>
                        <li>
                            Si. Te devolveremos el dinero según el medio de pago realizado, es decir, si pagaste con Webpay, se
                            devolverá a la cuenta de origen o tarjeta de pago siempre y cuando la tasación no haya sido realizada. Si
                            la tasación ya se realizó, no se puede devolver el dinero.
                        </li>
                    </ul>
                )
            case 2:
                return (
                    <ul>
                        <li>
                            Si. El servicio base contempla media hora de trabajo en terreno, considerando fotografía o video sin editar.
                            Si necesita fotogrametría con dron u otro servicio especializado debe solicitar cotización para ello.
                        </li>
                    </ul>
                )
            case 3:
                return (
                    <ul>
                        <li>
                            No. El servicio por UF40 incluye el trámite en el tribunal y redacción de la escritura de adjudicación. No
                            incluye honorarios de receptor judicial, alzamiento de garantías o CBR, los cuales son de cargo del cliente.
                        </li>
                    </ul>
                )
            default:
                break;
        }
    }

    return (
        <>
            {
                [
                    '1) Me arrepentí de una tasación pagada, ¿Pueden devolver mi dinero?',
                    '2) Para el servicio de dron, ¿Pueden realizar fotografías y video?',
                    '3) Me adjudiqué un inmueble en remate judicial, ¿El servicio incluye receptor judicial?',
                ].map((i, key) => (
                    <Root>
                        <AccordionMUI expanded={expanded === 'panel' + key} onChange={handleChange('panel' + key)}>
                            <AccordionSummary
                                expandIcon={<ChevronRight />}
                                aria-controls="panel1-content"
                                id="panel1-header">
                                <Typography variant='body1'>
                                    <b>{i}</b>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    getBody(key + 1)
                                }
                            </AccordionDetails>
                        </AccordionMUI>
                    </Root>
                ))
            }
        </>
    )
}

export default AccordionGarantias
