import Card from '@/components/Cards/Card/Card'
import UpButton from '@/components/UpButton/UpButton'
import Form from '@/modules/otherServices/Form'
import Table from '@/modules/otherServices/Table'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { IconImage, LogoPayment, RootButton, RootPayments, RootTitle } from './index.style'
import { Button } from '@/components/Buttons/Button'
import TablePay from '@/modules/otherServices/TablePay'

const OtherServices = () => {
  const iconSrc = require('@/assets/images/light-icon.webp')
  return (
    <div>
      <Grid container pt={5} pl={5} pr={5} maxWidth={1040} margin={'auto'} mt={3}>
        <Grid item xs={12}>
          <RootTitle>
            <div>
              <Typography variant='h4'>
                <b>¿Necesitas asesoría o apoyo personalizado?</b>
              </Typography>
              <IconImage src={iconSrc} />
            </div>
            <Typography variant='h5'>
              ¡Revisa nuestros servicios a medida!
            </Typography>
            <Typography variant='body1' align='center' mt={4}>
              En SeRemata® abordamos remates y grandes ofertas inmobiliarias,
              pero también entendemos que nuestros inversionistas necesitan apoyo especializado.
              Los montos en UF expuestos a continuación son valores base del servicio,
              los cuales varían en función a la locación y al tipo de requerimiento en cada uno de ellos. Valores netos.
            </Typography>
            <Typography variant='body1' align='center' mt={4} lineHeight={1.5}>
              <u>¿Te adjudicarás un remate judicial</u> y quieres apoyo con el papeleo?<br />
              <u>¿Consulta tributaria</u> ligada a remates o reventa de inmuebles?<br />
              <u>¿Necesitas fotografías</u> de un campo, terreno o alguna casa?<br />
              <u>¿Buscas tasar</u> alguna propiedad de forma física o virtual?<br />
              <u>¿Requieres un contrato</u> redactado por un abogado?<br />
              <u>¿Quieres aprender</u> sobre remates judiciales?
              <br />
              ¡Escríbenos!<br />
            </Typography>
          </RootTitle>
        </Grid>
        <Grid item xs={12} container justifyContent={'center'} mt={7}>
          <div style={{ maxWidth: 812 }}>
            <Table />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Card paddingInterior={60} paddingVertical={100} title='Completa el formulario' withContainer>
            <Form />
          </Card>
        </Grid>
        <Grid item container xs={12} justifyContent={'center'}>
          <Typography variant='h4'>Cuentas corrientes de servicios - SeRemata®</Typography>
          <Typography variant='body1' align='center' mt={4}>
            Disponemos de <b>1 cuenta corriente</b> del Banco de Chile para recibir tus depósitos o transferencias relacionadas a pago de servicios:
            <br />
            <br />
            <b>Pago servicios:</b> Puedes <u>depositar o transferir solo pago de servicios</u> solicitados en nuestro portal de “otros servicios”; tales como:
            Imágenes aéreas, tasaciones presenciales, tasaciones virtuales, contratos y cursos.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={10} container justifyContent={'center'}>
          <div style={{ maxWidth: 400 }}>
            <TablePay />
          </div>
        </Grid>
        <Grid item xs={12} mt={9} container justifyContent={'center'}>
          <Typography variant='h4'>
            Pago servicios con botón de pago - SeRemata®
          </Typography>
          <Typography variant='body1' align='center' mt={3}>
          Paga tu servicio indicando el número de presupuesto que te indicó el especialista vía correo electrónico o whatsapp.
            <br />
            <br />
            Revisa toda la información de botones de pago {' '}
            <a className='aDefault'>aquí.</a>
          </Typography>
        </Grid>
        <Grid container item xs={12} mt={3} mb={6}>
          <RootPayments>
            {
              [
                { source: require('@/assets/images/payments/bancoestado.webp') },
                { source: require('@/assets/images/payments/bancodechile.webp') },
                { source: require('@/assets/images/payments/santander.webp') },
                { source: require('@/assets/images/payments/webpay.webp') },
                { source: require('@/assets/images/payments/mercadopago.webp') },
              ].map(i => (
                <div>
                  <Card borderRadius={5}>
                    <LogoPayment style={{ backgroundImage: `url(${i.source})` }} />
                  </Card>
                  <RootButton>
                    <Button type='secondary'>Paga aquí</Button>
                  </RootButton>
                </div>
              ))
            }
          </RootPayments>
        </Grid>
        <Grid container item xs={12}>
          <UpButton />
        </Grid>
      </Grid>
    </div>
  )
}

export default OtherServices