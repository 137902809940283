import { maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${maxSizeMobile}) {
      flex-direction: column;
      margin-top: 20px;
   }
`

export const RootOrderFilter = styled.div`
    min-width: 320px;
    fieldset {
        border: none;
    }
    @media (max-width: ${maxSizeMobile}) {
        margin-top: 15px;
   }
  
`
