import styled from "@emotion/styled";

export const IconImage = styled.img`
 width: 68px;
`


export const RootTitle = styled.div`
    > div{
        text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    }
`
