
import { maxSizeMobile, theme } from "@/theme";
import { css } from "@emotion/css";

export const tableRoot = css`
  box-shadow: 0px 3px 10px rgba(0,0,0,.3);
  border-radius: 10px; 
  overflow: hidden;
  td, th {
    text-align: center;
  }
  @media (max-width: ${maxSizeMobile}) {
    p {
      font-size: 14px;
    }
   }
`
export const tableHead = css`
  background-color: ${theme.palette.primary.main};
`

export const tableCellHead = css`
  border-right: 1px solid black;
  border-bottom-color: black !important;
  > p {
    text-align: center;
  }
  :last-child {
    border-right: inherit;
  }
`

export const tableCell = css`
  border-right: 1px solid black;
  border-bottom: inherit !important;
  :last-child {
    border-right: inherit;
  }
`
export const tableRow = css`
 border-bottom: 1px solid black;
  :last-child {
    border-bottom: inherit !important;
  }
`