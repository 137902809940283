import styled from "@emotion/styled";

export const Root = styled.div`
   margin-bottom: 20px;
   .MuiPaper-root {
      box-shadow: inherit !important;
      border: 1px solid black;
   }
   .MuiPaper-root.Mui-expanded {
      border: none;
   }
`
export const ButtonStyle = styled.div`
   display: flex;
   align-items: center;
`
export const DogImage = styled.img`
   max-width: 180px;
   object-fit: contain;
   margin-bottom: -40px;
`
export const IconExclamation = styled.img`
   width: 30px;
   padding: 3px 0px;
   padding-right: 10px;
   margin-bottom: -10px;
`
export const RootButtonDog = styled.div`
   z-index: 10000;
   position: relative;
   > div > div {
      height: 40px;
      width: 260px;
   }
`
