import styled from "@emotion/styled";

export const Root = styled.div`
  input {
    padding-left: 20px;
  }
`
export const RootStart = styled.div`
  position: relative;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: black;
  }
  ::before {
    content: '';
    background-color: black;
    height: 100%;
    width: 1px;
    position: absolute;
    right: -6px;
    top:0px;
  }
`