import { Grid, MenuItem, Typography, Menu as MenuMui, } from '@mui/material'
import React from 'react'
import Menu from './Menu'
import { Avatar, GridLogin, GridMenu, GridMenuMobile, LoginButton, LogoStyle, Root, RootAccount, RootRight, rootHead } from './index.style'
import { Button } from '@/components/Buttons/Button'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import MenuIcon from '@/components/Icons/MenuIcon'
import Logo from '@/components/Logo/Logo'
import { useAuth } from '@/provider/AuthProvider'

const avatarSrc = require('@/assets/images/avatar.webp')

const Header = ({
    toggleDrawer
}) => {
    const navigate = useNavigate()
    const location = useLocation();
    const auth = useAuth()

    const executeScroll = () => {
        console.log("location.pathname", location.pathname)
        if (location.pathname !== '/aboutus') {
            navigate('/aboutus')
            setTimeout(() => {
                scrollToId('cardTeam')
            }, 100);
        } else {

            scrollToId('cardTeam')
        }

    }

    const scrollToId = (id) => {
        var element = document.getElementById(id);
        if (element) {
            var headerOffset = 40;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }
    return (
        <Root>
            <Grid container>
                <Grid className={rootHead} item xs={12} container alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item xs={6} md className={GridLogin} ml={3}>
                        <RootAccount>
                            <div>
                                <Avatar src={avatarSrc} />
                            </div>
                            {!auth.user
                                ? <div>
                                    <Button type='primary' onClick={() => navigate('/login')}
                                        className={LoginButton}
                                    >Accede a tu cuenta</Button>
                                    <Button className={LoginButton} onClick={() => navigate('/register')}>Regístrate aquí</Button>
                                </div>
                                : <AuthButton />
                            }
                        </RootAccount>
                    </Grid>
                    <Grid item mr={3} md>
                        <Link to='/' className={LogoStyle}>
                            <Logo />
                        </Link>
                    </Grid>
                    <Grid item className={GridMenu}>
                        <RootRight>
                            <Link to={'/aboutus'}>Nosotros</Link>
                            <Typography>|</Typography>
                            <a onClick={() => executeScroll()}>Únete a nuestro equipo</a>
                        </RootRight>
                    </Grid>
                    <Grid item xs={12} className={GridMenuMobile}>
                        <div>
                            <RootRight>
                                <Link to={'/aboutus'}>Nosotros</Link>
                                <Typography>|</Typography>
                                <a onClick={() => executeScroll()}>Únete a nuestro equipo</a>
                            </RootRight>
                            <div onClick={toggleDrawer}>
                                <MenuIcon />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Menu />
                </Grid>
            </Grid>
        </Root>
    )
}



const AuthButton = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate()
    const auth = useAuth()

    const logOut = () => {
        localStorage.removeItem('token')
        auth.signout(() => {
            navigate('/')
        })
        setAnchorEl(null);
    }

    return (
        <>
            <div onClick={(e) => handleClick(e)}>
                <Grid container spacing={3}>
                    <Grid item xs={8} container style={{cursor:'pointer'}}>
                        <Typography variant='body2'><b>{auth.user.name}</b></Typography>
                        <Typography variant='body2'>{auth.user.email}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Avatar />
                    </Grid>
                </Grid>
            </div>
            <MenuMui
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}

            >
                <MenuItem onClick={logOut}>
                    <Typography variant='body1' color="red">Cerrar sesión</Typography>
                </MenuItem>
            </MenuMui>
        </>
    )
}

export default Header