import React, { useState } from 'react';
import { Button } from '@/components/Buttons/Button';
import TextFieldPhone from '@/components/Inputs/TextFieldPhone/TextFieldPhone';
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

const Form = () => {
    const [names, setNames] = useState('');
    const [firstLastName, setFirstLastName] = useState('');
    const [secondLastName, setSecondLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [propertyValue, setPropertyValue] = useState('');
    const [appraised, setAppraised] = useState(true);
    const [inquiry, setInquiry] = useState('');

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='body1'>Datos de contacto</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        placeholder='Nombres'
                        fullWidth
                        value={names}
                        onChange={(e) => setNames(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        placeholder='Primer Apellido'
                        fullWidth
                        value={firstLastName}
                        onChange={(e) => setFirstLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        placeholder='Segundo Apellido'
                        fullWidth
                        value={secondLastName}
                        onChange={(e) => setSecondLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFieldPhone
                        placeholder='Teléfono'
                        fullWidth
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className='select-style'>
                        <FormControl fullWidth>
                            <InputLabel id="order-filter">$ Valor A de tu propiedad</InputLabel>
                            <Select
                                labelId='order-filter'
                                label='$ Valor A de tu propiedad'
                                value={propertyValue}
                                onChange={(e) => setPropertyValue(e.target.value)}
                            >
                                <MenuItem value="0 a 29 millones">0 a 29 millones</MenuItem>
                                <MenuItem value="30 a 79 millones">30 a 79 millones</MenuItem>
                                <MenuItem value="80 millones o más">80 millones o más</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={appraised}
                                onChange={(e) => setAppraised(e.target.checked)}
                            />
                        }
                        label="¿Tasada en los últimos 6 meses?"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        placeholder='Si tienes alguna consulta puedes escribirla aquí. (Máximo 50 palabras)'
                        fullWidth
                        value={inquiry}
                        onChange={(e) => setInquiry(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent={'center'}>
                    <Button type='secondary' style={{ minWidth: 250 }}><b>Enviar</b></Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default Form;
