import React, { useRef } from 'react'
import Card from '@/components/Cards/Card/Card'
import { Grid, Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'
import { CardMobile, CardMobile2, HouseImg, ImageIcon, PeopleChartImg, RootBody, } from './index.style'
import UpButton from '@/components/UpButton/UpButton'
import Form from '@/modules/aboutUs/Form'

const AboutUs = () => {
  const peopleChartSrc = require('@/assets/images/people-chart-seremata.webp')
  const remataHouseSrc = require('@/assets/images/se-remata-casa.webp')
  const petDogSrc = require('@/assets/images/pet-dog-seremata.webp')
  const handsPuzzleSrc = require('@/assets/images/hands-puzzle-seremata.webp')
  

  return (
    <>
      <div>
        <RootBody>
          <Card
            className={CardMobile}
            right={<ImageIcon src={petDogSrc} width={80} />}
            title={'Nosotros'}
            maxWidth={800}
            withContainer
            paddingInterior={50}
          >
            <div>
              <Typography variant='h4'><b>Misión</b></Typography>
              <br />
              <Typography variant='body1' textAlign={'justify'}>
                Facilitaremos el proceso de adquisición de propiedades a través de remates inmobiliarios online, ofreciendo una experiencia única y sin complicaciones, entregando un servicio personalizado, transparente y eficiente que les permita adjudicarse las mejores oportunidades de inversión inmobiliaria.
              </Typography>
              <br />
              <Typography variant='body1'>
                Somos una empresa Chilena que promete entregar herramientas tecnológicas e información fidedigna a cada inversionista para que logre un crecimiento financiero sostenible y exitoso.
              </Typography>
            </div>
            <div>
              <br />
              <br />
              <Typography variant='h4'><b>Visión</b></Typography>
              <br />
              <Typography variant='body1' textAlign={'justify'}>
                ¡La tenemos clara!: Al año 2030 seremos reconocidos como la opción predilecta para comprar propiedades en remates online, no solo por nuestra experiencia y profesionalismo, sino que también por la calidez y compromiso que brinda nuestro equipo en cada operación de compraventa.
              </Typography>
            </div>
          </Card>
          <Card right={<ImageIcon src={handsPuzzleSrc} width={80} />}
            className={CardMobile}
            title={'Nuestro equipo'} maxWidth={800} withContainer paddingInterior={50}>
            <div>
              <Typography variant='h4'><b>Compromiso</b></Typography>
              <br />
              <Typography variant='body1' textAlign={'justify'}>
                En el año 2011 dimos nuestros primeros pasos en la industria inmobiliaria, abordando gestiones de arriendos y ventas de algunas propiedades al año.
                A mediados del año 2013 saltamos al <b>“pragmático”</b> nicho de los remates inmobiliarios, tratando de reunir y entender la poca información que nos compartían.
                Al año 2016 ya habíamos logrado participar en varios remates inmobiliarios junto a un pequeño equipo de 4 personas,
                por lo que la forma de trabajar ya era clara en este punto. Al 2017 establecimos contratos de prestación de servicios con profesionales de 6 regiones,
                lo que nos permitió conocer el movimiento de remates judiciales en otras plazas, así como también, gestionar la venta de activos inmobiliarios en juicios de partición
                y liquidaciones concursales.
              </Typography>
              <br />
              <Typography variant='body1' textAlign={'justify'}>
                Hoy, nuestro foco es crear un marketplace inmobiliario único, donde las transacciones tengan procesos eficientes, seguros e informados. <b>¡Explicado en buen Chileno po!</b>.
              </Typography>
            </div>
          </Card>
          <Card  id={'cardTeam'} className={CardMobile2} maxWidth={800} withContainer paddingInterior={50} withoutBorder>
            <Grid container spacing={3}>
              <Grid xs={12} md={7} container justifyContent={'center'} alignItems={'center'}>
                <Grid container item xs={12} ml={2} mr={2}>
                  <Grid item xs={12} container justifyContent={'center'}>
                    <PeopleChartImg src={peopleChartSrc} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' align='center'>
                      <b>¿Te interesaría formar parte de nuestro equipo o tienes alguna idea de negocio?</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' align='center' mt={1}>
                      ¡Déjanos un mensaje y conversemos!
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container justifyContent={'center'} mt={4}>
                    <Button padding={'40px'} type='outlined' style={{ height: 35, width: 200 }}><b>Pincha aquí</b></Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5} container justifyContent={'flex-start'} alignItems={'center'}>
                <HouseImg src={remataHouseSrc} />
              </Grid>
            </Grid>
          </Card>
          <Card
            className={CardMobile}
            title={'Completa el formulario'} maxWidth={800} withContainer paddingInterior={50}>
            <Form />
          </Card>
          <div style={{ maxWidth: 900, margin: 'auto', marginTop: 50 }}>
            <UpButton />
          </div>
        </RootBody>
      </div>
    </>
  )
}

export default AboutUs