import { maxSizeMobile, theme } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    position: relative;
    box-shadow: 0px 4px 9px rgba(0,0,0,0.4);
`

export const RootContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
`
export const RootTitle = styled.div`
    position: absolute;
    top: -21px;
    background-color: white;
    > h4 {
        padding-left: 10px;
        padding-right: 10px;
        width: max-content;
    }
    @media (max-width: ${maxSizeMobile}) {
        top: -20px;
    }
`
export const rootTitleWithRight = css`
    left: 20px !important;
`
export const RootRight = styled.div`
    position: absolute;
    top: -50px;
    right: 50px;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    @media (max-width: ${maxSizeMobile}) {
        top: -44px;
        right: 19px;
        padding-left: 4px;
        padding-right: 4px;
    }

`
