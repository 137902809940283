
import { maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const RootBody = styled.div`
   margin: 20px 30px;
   @media (max-width: ${maxSizeMobile}) {
      margin: 50px 18px;
   }
`

export const PeopleChartImg = styled.img`
   width: 80px;
`
export const HouseImg = styled.img`
   width: 120%;
`
export const buttonStyle = css`
  background-color: red;
`

export const ImageIcon = styled.img`
  @media (max-width: ${maxSizeMobile}) {
            width: 33px;
            margin-top: 20px
        }
`

export const CardMobile = css`
 margin-top: 100px;
 @media (max-width: ${maxSizeMobile}) {
    margin-top: 30px;
    :first-child {
       margin-bottom: 56px;
    }
    > div {
       padding:  50px 25px !important;
      }
   }
   `
export const CardMobile2 = css`
margin-top: 70px;
  @media (max-width: ${maxSizeMobile}) {
   margin-top: 50px;
   
   }
`
