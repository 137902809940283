import React from 'react'
import AccordionMUI from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ChevronRight from '@/components/Icons/ChevronRight';
import { ButtonStyle, DogImage, Root, RootButtonDog } from './index.style';
import { Button } from '@/components/Buttons/Button';
import DownloadIcon from '@/components/Icons/DownloadIcon';

const AccordionGarantias = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const dogSrc = require('@/assets/images/dog.webp')

    const getBody = (i) => {
        switch (i) {
            case 1:
                return (
                    <ul>
                        <li>
                            No. Si compras un curso en el portal, dicho PDF no caduca, por lo que puedes utilizarlo por el tiempo que
                            gustes. Cuando existen cambios normativos o legales, se suben cursos actualizados que tienen valores
                            distintos, así como también, contenido alineado a la nueva ley o regulación.
                        </li>
                    </ul>
                )
            case 2:
                return (
                    <ul>
                        <li>
                            Sí. Una vez adquirido el curso, tienes 30 días corridos para elevar consultas por whatsapp, correo
                            electrónico (curso@seremata.cl) o vía telefónica. Solo debes indicar el ID o número de ticket de venta y el
                            nombre de quien realizó la compra, ya sea persona natura o jurídica. Videollamada o llamada de hasta 30
                            minutos
                        </li>
                    </ul>
                )
            case 3:
                return (
                    <ul>
                        <li>
                            Sí. Debes revisar el tipo de pago a realizar, ya que existen distintos medios de pago, tal como Mercado
                            Pago o Webpay, donde puedes pagar hasta en 3 cuotas precio contado (o más) si tienes convenio con tu
                            banco.
                        </li>
                    </ul>
                )
            default:
                break;
        }
    }

    return (
        <>
            {
                [
                    '1) ¿Los cursos en PDF expiran o caducan?',
                    '2) ¿Puedo solicitar asesoría telefónica o vía whatsapp en caso de dudas?',
                    '3) ¿Se puede pagar en cuotas?'
                ].map((i, key) => (
                    <Root>
                        <AccordionMUI expanded={expanded === 'panel' + key} onChange={handleChange('panel' + key)}>
                            <AccordionSummary
                                expandIcon={<ChevronRight />}
                                aria-controls="panel1-content"
                                id="panel1-header">
                                <Typography variant='body1'>
                                    <b>{i}</b>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    getBody(key + 1)
                                }
                            </AccordionDetails>
                        </AccordionMUI>
                    </Root>
                ))
            }
        </>
    )
}

export default AccordionGarantias
