import React, { useState, useEffect } from 'react';
import { Button } from '@/components/Buttons/Button';
import TextFieldPhone from '@/components/Inputs/TextFieldPhone/TextFieldPhone';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import config from '@/config';

const Form = () => {
    const [names, setNames] = useState('');
    const [firstLastName, setFirstLastName] = useState('');
    const [secondLastName, setSecondLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [service, setService] = useState('');
    const [region, setRegion] = useState('');
    const [commune, setCommune] = useState('');
    const [inquiry, setInquiry] = useState('');

    const [regions, setRegions] = useState([]);
    const [communes, setCommunes] = useState([]);
    const [filteredCommunes, setFilteredCommunes] = useState([]);

    const services = [
        'Contrato Arriendo',
        'Consulta Tributaria',
        'Imágenes Aéreas',
        'Promesa Compraventa',
        'Tasación Virtual',
        'Tasación Presencial',
        'Mandato General',
        'Contrato Compraventa',
        'Estudio de Títulos',
        'Posesión Efectiva',
        'Testamentos',
        'Adjudicación Judicial',
        'Curso Corretaje Propiedades',
        'Curso Remates Judiciales'
    ];

    useEffect(() => {
        const fetchRegionsAndCommunes = async () => {
            try {
                const [regionsRes, communesRes] = await Promise.all([
                    axios.get(`${config.API_URL}/location/regions`),
                    axios.get(`${config.API_URL}/location/communes`)
                ]);
                setRegions(regionsRes.data);
                setCommunes(communesRes.data);
            } catch (error) {
                console.error('Error fetching regions and communes:', error);
            }
        };
        fetchRegionsAndCommunes();
    }, []);

    useEffect(() => {
        if (region) {
            setFilteredCommunes(communes.filter(commune => commune.region.id === region));
        } else {
            setFilteredCommunes([]);
        }
    }, [region, communes]);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='body1'>Datos de contacto</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        placeholder='Nombres'
                        fullWidth
                        value={names}
                        onChange={(e) => setNames(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        placeholder='Primer Apellido'
                        fullWidth
                        value={firstLastName}
                        onChange={(e) => setFirstLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        placeholder='Segundo Apellido'
                        fullWidth
                        value={secondLastName}
                        onChange={(e) => setSecondLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextFieldPhone
                        placeholder='Teléfono'
                        fullWidth
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth className='select-style'>
                        <InputLabel id="service-label">Servicio que deseas</InputLabel>
                        <Select
                            labelId='service-label'
                            label='Servicio que deseas'
                            value={service}
                            onChange={(e) => setService(e.target.value)}
                        >
                            {services.map((service, index) => (
                                <MenuItem key={index} value={service}>{service}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth className='select-style'>
                        <InputLabel id="region-label">Región</InputLabel>
                        <Select
                            labelId='region-label'
                            label='Región'
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                        >
                            {regions.map(region => (
                                <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth className='select-style'>
                        <InputLabel id="commune-label">Comuna</InputLabel>
                        <Select
                            labelId='commune-label'
                            label='Comuna'
                            value={commune}
                            onChange={(e) => setCommune(e.target.value)}
                            disabled={!region}
                        >
                            {filteredCommunes.map(commune => (
                                <MenuItem key={commune.id} value={commune.id}>{commune.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        placeholder='Si tienes alguna consulta puedes escribirla aquí. (Máximo 50 palabras)'
                        fullWidth
                        value={inquiry}
                        onChange={(e) => setInquiry(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent={'center'}>
                    <Button type='secondary' style={{ minWidth: 250 }}><b>Enviar</b></Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default Form;
