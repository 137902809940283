import { maxSizeMackbook, maxSizeMobile } from "@/theme";
import styled from "@emotion/styled";

export const Root = styled.div`
   margin-right: 10%;
   margin-top: 10px;
   @media (max-width: ${maxSizeMobile}) {
      margin-right: 0px;
      margin-top: 0px;
   }
`

export const RootAccordion = styled.div`
   margin-top: 20px;

   > .MuiPaper-root>div:first-child {
      background-color: #F1F1F1;
   }
`