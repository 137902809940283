
import { maxSizeMobile, maxSizeTablet, theme } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
  position: relative;
  @media (max-width: ${maxSizeMobile}) {
    p {
      font-size: 14px;
    }
   }
`
export const DogSideImage = styled.img`
  position: absolute;
  right: -200px;
  top: 70px;
  width: 200px; 
  @media (max-width: ${maxSizeTablet}) {
    width: 80px; 
    right: -80px;
  }
`

export const tableRoot = css`
  box-shadow: 0px 3px 10px rgba(0,0,0,.3);
  border-radius: 10px; 
  overflow: hidden;
  td, th {
    padding: 8px 20px;
    text-align: center;
  }
  @media (max-width: ${maxSizeTablet}) {
   p {
    font-size: 12px;
   }
   td, th {
      padding: 8px 4px;
      width: 100px;
    }
  }
`
export const tableHead = css`
  background-color: ${theme.palette.primary.main};
`

export const tableCellHead = css`
  border-right: 1px solid black;
  border-bottom-color: black !important;
  :last-child {
    border-right: inherit;
  }
`

export const tableCell = css`
  border-right: 1px solid black;
  border-bottom: inherit !important;
  :last-child {
    border-right: inherit;
  }
`
export const tableRow = css`
 border-bottom: inherit !important;
  :last-child {
    border-bottom: inherit !important;
  }
`