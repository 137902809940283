import React from 'react'
import { Table as TableMUI, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { tableCell, tableCellHead, tableHead, tableRoot, tableRow } from './index.style'

const Table = ({
    property
}) => {
    return (
        <TableMUI className={tableRoot}>
            <TableHead className={tableHead}>
                <TableRow className={tableRow}>
                    <TableCell colSpan={2} className={tableCellHead}>
                        <Typography variant='body1'><b>DETALLE DE LA PROPIEDAD</b></Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    [
                        ['Tipo', property.type.name],
                        ['Región', property.region.name],
                        ['Comuna', property.commune.name],
                        ['Rol SII', property.rolSii],
                        ['Dormitorios', property.bedrooms],
                        ['Baños', property.bathrooms],
                        ['Estacionamientos', property.parking],
                        ['Superficie Total', property.size + ' m²'],
                        ['Propiedad Ocupada', property.occupiedProperty ? 'Si' : 'No'],
                        ['Mandante', property.mandante.name],
                        ['Días corridos para pagar adjudicación:', property.daysToPay],
                    ].map(i => (
                        <TableRow className={tableRow}>
                            <TableCell className={tableCell}>
                                <Typography variant='body2'><b>{i[0]}</b></Typography>
                            </TableCell>
                            <TableCell className={tableCell}>
                                <Typography variant='body2'>{i[1]}</Typography>
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </TableMUI>
    )
}

export default Table