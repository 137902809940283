import React from 'react'
import { Table as TableMUI, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { ImageUpdate, Root, RootStar, tableCell, tableCellHead, tableHead, tableRoot, tableRow } from './index.style'
import StarIcon from '@/components/Icons/StarIcon'
const updateSrc = require('@/assets/images/in-update.webp')

const Table = () => {
    return (
        <Root>
            <TableMUI className={tableRoot}>
                <TableHead className={tableHead}>
                    <TableRow>
                        <TableCell className={tableCellHead} colSpan={2}>
                            <Typography variant='body2'><b>Nombre corredor</b></Typography>
                        </TableCell>
                        <TableCell className={tableCellHead}>
                            <Typography variant='body2'><b>Whatsapp</b></Typography>
                        </TableCell>
                        <TableCell className={tableCellHead}>
                            <Typography variant='body2'><b>Correo</b></Typography>
                        </TableCell>
                        <TableCell className={tableCellHead}>
                            <Typography variant='body2'><b>Región</b></Typography>
                        </TableCell>
                        <TableCell className={tableCellHead}>
                            <Typography variant='body2'><b>Calificación</b></Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>1</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>Nombre Apellido</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>+569774432</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>ejemplo@gmail.com</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>Metropolitana</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <RootStar>
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </RootStar>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>2</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>Nombre Apellido</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>+569774433</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>ejemplo@gmail.com</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>Metropolitana</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <RootStar>
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon style={{ opacity: 0.2 }} />
                            </RootStar>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>3</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>Nombre Apellido</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>+569774433</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>ejemplo@gmail.com</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <Typography variant='body2'>Metropolitana</Typography>
                        </TableCell>
                        <TableCell className={tableCell}>
                            <RootStar>
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon style={{ opacity: 0.2 }} />
                            </RootStar>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </TableMUI>
            <ImageUpdate src={updateSrc} />
        </Root>
    )
}

export default Table