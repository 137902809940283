import { maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    background-color: #F3F3F3;
    padding-top: 50px;
    padding-bottom: 80px;
    margin-top: 40px;
    padding-left: 7%;
    padding-right: 7%;
    position: relative;
`

export const RootBottom = styled.div`
    background-color: #F9CB48;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
`
export const SocialImg = styled.img`
   width: 35px;
`
export const logoStyle = css`
    @media (max-width: ${maxSizeMobile}) {
        width: 100%;
        justify-content: center;
        display: flex;
    }
`