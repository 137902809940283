import { Button } from '@/components/Buttons/Button'
import Carrousel from '@/modules/property/Carrousel/Carrousel'
import { Grid, Typography, useMediaQuery, CircularProgress, Container } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { IconsImages, MapBackground, Root, RootCarrousel, RootElements, mapButton, rootGrid } from './index.style'
import List from '@/modules/property/List/List'
import UpButton from '@/components/UpButton/UpButton'
import WhatsappIcon from '@/components/Icons/WhatsappIcon'
import Table from '@/modules/property/Table'
import axios from 'axios'
import { theme } from '@/theme'
import { separadorMiles } from '@/utils/separadorMiles'
import config from '@/config';

const boxShadowSrc = require('@/assets/images/elements/box-shadow_seremata.png')
const bedSrc = require('@/assets/images/elements/bed_seremata.png')
const showerSrc = require('@/assets/images/elements/shower_seremata.png')
const metroSrc = require('@/assets/images/elements/metro-santiago_seremata.png')
const mapSrc = require('@/assets/images/map.webp')

const Property = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  console.log("property", property)
  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await axios.get(config.API_URL + `/properties/${id}`);
        setProperty(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching property', error);
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!property) {
    return (
      <Container>
        <Typography variant="h5" color='rgba(0,0,0,0.4)'>Selecciona una propiedad</Typography>
      </Container>
    );
  }

  return (
    <Root>
      <Grid container spacing={isSmall ? 0 : 5} maxWidth={1900} margin={'auto'}>
        <Grid item xs={12} md={6}>
          <RootCarrousel>
            <Carrousel images={property.images} />
          </RootCarrousel>
        </Grid>
        <Grid className={rootGrid} container item xs={12} md={6}>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12}>
              <Typography variant='caption'>Precio mínimo</Typography>
              <Typography variant='h2'><b>UF {separadorMiles(property.price)}</b></Typography>
              {/* <Typography variant='h5'><b>{property.city.name}</b></Typography> */}
              <br />
              <Typography variant='body1'>{property.address}</Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{ maxWidth: 230 }}>
                <Button type='secondary'>
                  <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                    <WhatsappIcon fill={'white'} />
                    <a href='https://api.whatsapp.com/send/?phone=%2B56971403094&text&type=phone_number&app_absent=0' target='_blank'>
                      <Typography variant='body2' style={{ marginLeft: 15, marginRight: 20 }}>
                        Venta directa
                      </Typography>
                    </a>
                  </div>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} mt={3}>
              <Typography variant='body2'>
                <b>Fecha subasta:</b><br />
                {new Date(property.auctionDate).toLocaleDateString()}
              </Typography>
              <br />
              <Typography variant='body2'>
                <b>Porcentaje de cobro por venta:</b><br />
                {property.salePercentage}%
              </Typography>
              <br />
              <Typography variant='body2'>
                <b>Abona una garantía de:</b><br />
                $ {separadorMiles(property.priceGuarantee)}
              </Typography>
              <br />
            </Grid>
            <Grid item xs={12}>
              <div style={{ maxWidth: 230 }}>
                <Button type='secondary' style={{ paddingLeft: 30, paddingRight: 30 }}>Inscríbete ahora</Button>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <RootElements>
              <div>
                <div>
                  <IconsImages src={boxShadowSrc} />
                  <Typography variant='body2' fontSize={12}><b>{property.size} m2</b></Typography>
                </div>
                <div>
                  <IconsImages src={bedSrc} />
                  <Typography variant='body2' fontSize={12}><b>{property.bedrooms} Dormitorio(s)</b></Typography>
                </div>
                <div>
                  <IconsImages src={bedSrc} />
                  <Typography variant='body2' fontSize={12}><b>Casa</b></Typography>
                </div>
              </div>
              <div>
                <div>
                  <IconsImages src={showerSrc} />
                  <Typography variant='body2' fontSize={12}><b>{property.bathrooms} Baño(s)</b></Typography>
                </div>
                <div>
                  <IconsImages src={metroSrc} />
                  <Typography variant='body2' fontSize={12}><b>{property.metroStation?.name}</b></Typography>
                </div>
                <div>
                  <IconsImages src={metroSrc} />
                  <Typography variant='body2' fontSize={12}><b>{property.commune.name}</b></Typography>
                </div>
              </div>
            </RootElements>
          </Grid>
        </Grid>
        <Grid style={{ paddingLeft: 0 }} className={rootGrid} item xs={12} md={6} mt={5} container justifyContent={'flex-end'}>
          <Grid item xs container justifyContent={'center'}>
            <div style={{ maxWidth: 660 }}>
              <Table property={property} />
              <Typography variant='body2' mr={3} mb={6} mt={5} textAlign={'justify'}>
                {property.description}
              </Typography>
            </div>
            <br />
          </Grid>
        </Grid>
        <Grid className={rootGrid} item xs={12} md={6} mt={5}>
          <Typography variant='h5'><b>Documentos descargables</b></Typography>
          <br />
          <br />
          <div>
            <div>
              <Typography variant='body1'><b><u>Antecedentes de la propiedad</u></b></Typography>
            </div>
            <ul>
              <li><Typography variant='body1'>Bases</Typography></li>
              <li><Typography variant='body1'>Procedimiento</Typography></li>
            </ul>
          </div>
          <br />
          <div>
            <div>
              <Typography variant='body1'><b><u>Condiciones del remate o subasta</u></b></Typography>
            </div>
            <ul>
              <li><Typography variant='body1'>Bases</Typography></li>
              <li><Typography variant='body1'>Procedimiento</Typography></li>
            </ul>
          </div>
        </Grid>
        <Grid className={rootGrid} item xs={12} style={{ maxWidth: 1400, margin: 'auto' }}>
          <Typography variant='h4'><b>Multimedia</b></Typography>
          <a href={property.urlLocation} target='_blank'>
          <Button type='secondary' className={mapButton}><b>Ver en Google Maps</b></Button>
          </a>
          <MapBackground style={{ backgroundImage: `url(${mapSrc})` }} />
        </Grid>
        <Grid className={rootGrid} item xs={12} mt={6} mb={5} style={{ maxWidth: 1400, margin: 'auto' }}>
          <Typography variant='h4'><b>Propiedades similares</b></Typography>
        </Grid>
        <Grid className={rootGrid} item xs={12} style={{ maxWidth: 1400, margin: 'auto' }}>
          <List />
        </Grid>
        <Grid className={rootGrid} item xs={12} mt={3} style={{ maxWidth: 1400, margin: 'auto' }}>
          <UpButton />
        </Grid>
      </Grid>
    </Root>
  )
}

export default Property;
