import Card from '@/components/Cards/Card/Card'
import Accordion from '@/modules/freeContracts/Accordion'
import Form from '@/modules/otherServices/Form'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { CaraSonriendoImg, RootTitle } from './index.style'
import Table from '@/modules/freeContracts/Table'
import UpButton from '@/components/UpButton/UpButton'

const FreeContracts = () => {
  const caraSonriendoSrc = require('@/assets/images/cara_sonriendo_seremata-2.webp')

  return (
    <div>
      <Grid container pt={5} pl={5} pr={5} maxWidth={1130} margin={'auto'} mt={5}>
        <Grid item xs={12} container justifyContent={'center'}>
          <RootTitle>
            <Typography variant='h4'>
              <b>¿Contratos gratis?. ¡Sí, gratis!</b>
            </Typography>
            <CaraSonriendoImg src={caraSonriendoSrc} />
          </RootTitle>
          <Typography variant='h5' mt={-1}>
            Descárgalos uno a uno en los módulos desplegables
          </Typography>
          <br />
          <Typography variant='body1' mt={5} align='center'>
            Dejamos a tu disposición y sin costo alguno, 10 documentos para que los puedas editar según tus necesidades.<br/>
            De igual forma, siempre recomendamos completarlos junto a tu asesor financiero, inmobiliario o legal.
            <br /><br />
            Si tienes alguna propuesta para apoyar a otras personas o emprendedores en el rubro inmobiliario <br/>
            <b>¡Escríbenos y hagamos crecer este marketplace entre todos!.</b>
          </Typography>
        </Grid>
        <Grid item xs={12} mt={5}>
          <Accordion />
        </Grid>
        <Grid item xs={12} mt={7}>
          <Typography variant='h4' align='center'>
            Si lo que buscas es algo hecho a tu medida por un profesional:  <br/> ¡Completa el formulario!
          </Typography>
          <br />
          <Typography variant='subtitle2' align='center'>
            Un ejecutivo de nuestra empresa te contactará vía correo electrónico o whatsapp para que abordemos en conjunto tu requerimiento
          </Typography>
          <br />
        </Grid>
        <Grid item xs={12} container justifyContent={'center'} mt={5}>
          <div style={{ maxWidth: 812 }}>
            <Table />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Card paddingInterior={60} paddingVertical={100} title='Completa el formulario' withContainer>
            <Form />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <UpButton />
        </Grid>
      </Grid>
    </div>
  )
}

export default FreeContracts