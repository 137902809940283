
import styled from "@emotion/styled";

export const CaraSonriendoImg = styled.img`
  width: 50px;
  margin-left: 10px;
`
export const RootTitle = styled.div`
    margin-bottom: 20px;
    text-align: center;
  > div {
      display: flex;
      align-items: center;
      justify-content: center;
    > h3 {
      margin-right:20px;
    }
  }
`