import UpButton from '@/components/UpButton/UpButton'
import AccordionCostos from '@/modules/question/AccordionCostos'
import AccordionCursos from '@/modules/question/AccordionCursos'
import AccordionGarantias from '@/modules/question/AccordionGarantias'
import AccordionServicios from '@/modules/question/AccordionServicios'
import { Grid, Typography } from '@mui/material'
import React from 'react'

const Question = () => {
    return (
        <Grid container pt={5} pl={5} pr={5} maxWidth={1000} margin={'auto'} mt={5}>
            <Grid item xs={12}>
                <Typography variant='h3'><b>Garantías</b></Typography>
            </Grid>
            <Grid item xs={12} mt={5}>
                <AccordionGarantias />
            </Grid>
            <Grid item xs={12} mt={6}>
                <Typography variant='h3'><b>Cursos</b></Typography>
            </Grid>
            <Grid item xs={12} mt={5}>
                <AccordionCursos />
            </Grid>
            <Grid item xs={12} mt={6}>
                <Typography variant='h3'><b>Costos y condiciones de una adjudicación</b></Typography>
            </Grid>
            <Grid item xs={12} mt={5}>
                <AccordionCostos />
            </Grid>
            <Grid item xs={12} mt={6}>
                <Typography variant='h3'><b>Servicios</b></Typography>
            </Grid>
            <Grid item xs={12} mt={5}>
                <AccordionServicios />
            </Grid>
            <Grid item xs={12} mt={5}>
                <UpButton />
            </Grid>
        </Grid>
    )
}

export default Question