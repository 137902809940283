import { maxSizeMobile, maxSizeTablet, theme } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
`

export const Avatar = styled.img`
    width: 60px;
    @media (max-width: ${maxSizeTablet}) {
        width: 44px;
        margin-top: -23px;
   }
`

export const LogoStyle = css`
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: ${maxSizeTablet}) {
       > img {
        width: 94px !important;
       }
        position: inherit;
    }
`

export const rootHead = css`
        min-height: 160px;
    @media (max-width: ${maxSizeMobile}) {
            min-height: inherit;;
    }
   
`

export const RootAccount = styled.div`
    display: flex;  
    align-items: center;
    > div:first-child {
        
    }
    > div:last-child { 
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10px;
    }
`

export const RootRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    > a {
        margin-left: 10px;
        margin-right: 10px;
    }
    @media (max-width: ${maxSizeTablet}) {
            a {
                font-size: 14px !important;
                margin-left: 9px;
                margin-right: 9px;
            }
        }
`
export const GridLogin = css`
    height: 105px;
    display: flex;
    align-items: center;
`

export const GridLoginMobile = css`
    display: none;
    padding-left: 20px;
    padding-right: 20px;
      @media (max-width: ${maxSizeTablet}) {
       display: block;
       position: relative;
   }
`

export const GridMenu = css`
  padding-right: 30px;
  @media (max-width: ${maxSizeTablet}) {
       display: none;
    }
`
export const GridMenuMobile = css`
    display: none;
    > div {
        background-color: ${theme.palette.background.default};
        height: 38px;
        display: flex;
        justify-content: space-between;;
        align-items: center;
        padding-right: 20px;
        padding-left: 20px;
    }
    @media (max-width: ${maxSizeTablet}) {
       display: block;
    }
`
export const LoginButton = css`
    > div {
        border-radius: 10px; 
        font-weight: 600px;
        height: 37px;
        @media (max-width: ${maxSizeMobile}) {
            height: 26px;
           > p {
                font-size: 12px !important;
           }
        }
    }

`