import React from 'react'
import AccordionMUI from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ChevronRight from '@/components/Icons/ChevronRight';
import { ButtonStyle, DogImage, Root, RootButtonDog } from './index.style';
import { Button } from '@/components/Buttons/Button';
import DownloadIcon from '@/components/Icons/DownloadIcon';

const AccordionGarantias = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const dogSrc = require('@/assets/images/dog.webp')

    const getBody = (i) => {
        switch (i) {
            case 1:
                return (
                    <ul>
                        <li>Si transferiste, depositaste o pagaste garantía por cualquiera de estos medios de pago, el valor se te
                            devolverá en un 100%. Si depositaste en efectivo, el valor se transferirá a tu cuenta bancaria registrada.
                        </li>
                    </ul>
                )
            case 2:
                return (
                    <ul>
                        <li>
                            Puedes dejar el dinero indefinidamente en tu wallet.
                        </li>
                    </ul>
                )
            case 3:
                return (
                    <ul>
                        <li>
                            Sí, puedes participar en cualquier remate teniendo un saldo mínimo de $3.000.000, el cual deberás abonar
                            para participar.

                        </li>
                    </ul>
                )
            case 4:
                return (
                    <ul>
                        <li>
                            Si participas en una subasta o puja online y resultas ganador de ella, tienes un plazo de días corridos
                            (indicado en las bases de cada remate) para enterar el valor total. Si no logras enterar dicho valor después
                            del plazo establecido, tu garantía no se devolverá puesto que incurriste en una falta conociendo los plazos
                            del mandante. Al entrar a una puja online, te recomendamos revisar las condiciones y bases legales.
                        </li>
                    </ul>
                )
            default:
                break;
        }
    }

    return (
        <>
            {
                [
                    '1) ¿Qué pasa si no gano la puja o subasta en línea?',
                    '2) ¿Por cuanto tiempo puedo dejar mi garantía en el wallet sin movimiento?',
                    '3) ¿Si tengo saldo en mi wallet puedo participar en cualquier subasta?',
                    '4) ¿Que pasa si gano la puja y no logro enterar la diferencia de adjudicación?',
                ].map((i, key) => (
                    <Root>
                        <AccordionMUI expanded={expanded === 'panel' + key} onChange={handleChange('panel' + key)}>
                            <AccordionSummary
                                expandIcon={<ChevronRight />}
                                aria-controls="panel1-content"
                                id="panel1-header">
                                <Typography variant='body1'>
                                    <b>{i}</b>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    getBody(key + 1)
                                }
                            </AccordionDetails>
                        </AccordionMUI>
                    </Root>
                ))
            }
        </>
    )
}

export default AccordionGarantias