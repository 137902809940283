import React from 'react'

const DownloadIcon = ({...props}) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" fill="black"/>
    </svg>
    
  )
}

export default DownloadIcon