import React from 'react'
import Card from '@/components/Cards/Card/Card'
import { Grid, Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'
import { LogoPayment, RootButton, RootPayments } from './index.style'
import Table from '@/modules/auction/Table'
import UpButton from '@/components/UpButton/UpButton'
import Accordion from '@/modules/auction/Accordion'

const Auction = () => {
    const dogGlassesBlue = require('@/assets/images/dog-glasses-blue.webp')
    const dogGlassesOrange = require('@/assets/images/dog-glasses-orange.webp')
    return (
        <>
            <div>
                <Grid container pt={5} pl={5} pr={5} maxWidth={1000} margin={'auto'} mt={3}>
                    <Grid item xs={12} container justifyContent={'center'}>
                        <Typography variant='h4'>
                            <b>
                                ¿Deseas comprar a través de un remate online?
                            </b>
                        </Typography>
                        <Typography variant='h5' mt={2}>
                            !Súmate a nuestra comunidad e invierte con seguridad!
                        </Typography>
                        <Typography variant='body1' align='center' mt={5} >
                            A continuación, descarga los PDF en donde encontrarás el paso a paso para comprender todo el proceso de adjudicación con SeRemata® y de tu Wallet. Si prefieres un resumen, pincha los módulos desplegables para leer el proceso acotado.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent={'center'}>
                        <Grid item xs={12} mt={10} container justifyContent={'center'} style={{ maxWidth: '500px' }} spacing={3}>
                            <Grid item xs={12} md={6} container justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                                <img src={dogGlassesBlue} style={{width: 155}} />
                                <div>
                                    <Button style={{ height: 40, width: 230, marginTop: -5 }} type='outlined'>
                                        <b>PASO A PASO</b>
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} container justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                                <img src={dogGlassesOrange} style={{
                                    width: 155,
                                }} />
                                <div>
                                    <Button style={{ height: 40, width: 230, marginTop: -4 }} type='outlined'><b>BILLETERA | WALLET</b></Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mt={10}>
                        <Accordion />
                    </Grid>
                    <Grid item container xs={12} mt={10} justifyContent={'center'}>
                        <Typography variant='h4'>Cuentas corrientes de subastas - SeRemata®</Typography>
                        <Typography variant='body1' align='center' mt={4}>
                            Disponemos de <b>2 cuentas corrientes</b> del Banco de Chile para recibir tus depósitos y transferencias relacionadas a remates de inmuebles,
                            recuerda que cada una es independiente:
                            <br />
                            <br />
                            <b>1) Pago de garantía:</b> Puedes depositar o transferir solo garantías mínimas (entre 1 y 20) para que se sumen a tu wallet
                            <br />
                            <br />
                            <b>2) Pago adjudicación:</b> Puedes depositar o transferir solo diferencias de adjudicación y gastos de la adjudicación (costos de gestión e inscripción).
                            Recuerda que el “saldo de adjudicación o diferencia de adjudicación” se refiere al valor adjudicado menos la garantía mínima ya retenida.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={10}>
                        <Table />
                    </Grid>
                    <Grid item xs={12} mt={9} container justifyContent={'center'}>
                        <Typography variant='h4'>
                            Botones de pago para garantía - SeRemata®
                        </Typography>
                        <Typography variant='body1' align='center' mt={3}>
                            Otras alternativas de pago distintas al uso de wallet y transferencia bancaria son los botones de pago.
                            <br />
                            <br />
                            Revisa toda la información de botones de pago {' '}
                            <a className='aDefault'>aquí.</a>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} mt={3} mb={6}>
                        <RootPayments>
                            {
                                [
                                    { source: require('@/assets/images/payments/bancoestado.webp') },
                                    { source: require('@/assets/images/payments/bancodechile.webp') },
                                    { source: require('@/assets/images/payments/santander.webp') },
                                    { source: require('@/assets/images/payments/webpay.webp') },
                                    { source: require('@/assets/images/payments/mercadopago.webp') },
                                ].map(i => (
                                    <div>
                                        <Card borderRadius={5}>
                                            <LogoPayment style={{ backgroundImage: `url(${i.source})` }} />
                                        </Card>
                                        <RootButton>
                                            <Button type='secondary'>Paga aquí</Button>
                                        </RootButton>
                                    </div>
                                ))
                            }
                        </RootPayments>
                    </Grid>
                    <Grid container item xs={12} mt={3} mb={6} spacing={3}>
                        <UpButton />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Auction